.footer {
  width: 100%;
  margin-bottom: 80px;

  &__body {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $mainColor;
    padding: 10px 30px;
    border-radius: $radius;
    border-top: 4px solid $mainDarkColor;
    color: white;

    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
      align-items: flex-start;
      padding: 22px;
    }
  }

  &__logo {
    max-width: 120px;
    margin-right: 3%;

    @media screen and (max-width: 768px) {
      max-width: 300px;
      margin-bottom: 10px;
      margin-right: 0px;
      text-align: center;
    }

    img {
      max-height: 40px;
      min-height: 40px;
      height: 100%;
      width: auto;
      vertical-align: top;
    }
  }

  &__menu {
    padding-right: 30px;
    padding-bottom: 21px;

    @media screen and (max-width: 768px) {
      padding-right: 0;
      width: 100%;
      order: 1;
    }
  }

  &__copyright {
    padding: 20px 0;
    @include text-style(normal, 12px, 14px, initial, $greyColor);
  }
}


.logo__box {
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 3%;

  @media screen and (max-width: 768px) {
    margin-right: 0px;
    width: 100%;
    flex-direction: column;
  }

  a {
    width: 100%;

  }
}










// .comment {
//   max-width: 945px;
//   margin-bottom: 30px;
//   margin-left: auto;
//   margin-right: auto;
//   padding: 30px;
//   border: 1px solid #C4C9CE;
//   list-style: none;
//   border-radius: 2px;



//   &-form {
//     display: flex;
//     flex-direction: column;
//     max-width: 945px;
//     margin-left: auto;
//     margin-right: auto;

//     .logged-in-as,
//     .comment-notes {
//       display: none;
//     }

//     textarea,
//     .comment-form-author input {
//       width: 100%;
//       resize: none;
//       border: 1px solid #C4C9CE;
//       border-radius: 2px;
//       background: #EEF1F3;
//       color: #6C7277;
//       border-radius: 2px;
//     }

//     textarea {
//       font-size: 14px;
//       line-height: 22px;
//       padding: 10px 26px;
//       border-radius: 2px;
//     }

//     &-title {
//       margin-bottom: 20px;
//       display: flex;
//       padding-left: 20px;
//       position: relative;

//       &::before {
//         content: '';
//         display: block;
//         width: 10px;
//         height: 100%;
//         background: #00A660;
//         border-radius: 2px;
//         position: absolute;
//         left: 0;
//         top: 0;
//       }
//     }

//     .author {
//       padding: 4px 13px;
//       line-height: 22px;
//       max-width: 360px;
//       margin-bottom: 20px;
//     }

//     &-comment {
//       order: 4;
//       margin-bottom: 40px;
//       display: flex;
//       padding-left: 20px;
//       position: relative;

//       &::before {
//         content: '';
//         display: inline-block;
//         width: 10px;
//         height: 100%;
//         background: #C50000;
//         border-radius: 2px;
//         position: absolute;
//         left: 0;
//         top: 0;
//       }
//     }

//     &-rating {
//       order: -1;
//       // display: flex;
//       margin-bottom: 20px;
//       position: relative;

//       .fl {
//         display: flex;
//       }

//       .commentratingbox {
//         // display: flex;

//         input {
//           display: none;
//         }

//         // .commentrating>input:checked {
//         //   color: red
//         // }

//         &-text {
//           order: 3;
//         }
//       }
//     }

//     &-author {
//       order: -2;
//     }


//     .form-submit {
//       order: 5;
//       text-align: center;

//       input {
//         // display: block;
//         font-weight: bold;
//         font-size: 18px;
//         line-height: 22px;
//         color: #474B4F;
//         padding: 4px;
//         background: transparent;
//         border: 1px solid #474B4F;
//         width: 100%;
//         max-width: 288px;

//         &:hover {
//           background: #474B4F;
//           color: #fff;
//         }
//       }
//     }
//   }

//   &-rating {
//     margin-top: 34px;
//     margin-bottom: 34px;
//     font-size: 28px;
//     line-height: 33px;
//   }

//   &-author {
//     display: flex;

//     .com-meta {
//       margin-left: 10px;
//     }

//     a {
//       font-size: 16px;
//       line-height: 18px;
//       font-weight: bold;
//       color: #404040;
//       text-transform: capitalize;
//     }
//   }

//   &-reply-title {
//     font-size: 32px;
//     line-height: 37px;
//     font-weight: normal;
//     text-transform: uppercase;
//     margin-bottom: 44px;
//   }

// }


// .rate-top {
//   display: flex;
//   align-items: center;

//   .fl {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     order: -1;
//     margin-right: 20px;
//   }

//   .cm-placeholder {
//     font-weight: bold;
//     font-size: 28px;
//     line-height: 33px;
//     color: #6C7277;
//     background: #EEF1F3;
//     border: 1px solid #C4C9CE;
//     width: 50px;
//     height: 50px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }

//   .dwn {
//     background: #C4C9CE;
//     width: 50px;
//     height: 50px;
//     // display: block;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     cursor: pointer;

//     &::after {
//       content: '';
//       display: inline-block;
//       width: 0;
//       height: 0;
//       border-style: solid;
//       border-width: 20px 10px 0;
//       border-color: #fff transparent transparent transparent;
//     }

//   }
// }
// .commentratingbox {
//   display: flex;
//   flex-direction: column;
//   width: 50px;
//   position: absolute;
//   z-index: 3;
//   display: none;

//   &.active {
//     display: flex;
//   }

//   label {
//     background-color: #fff;
//     padding: 2px;
//     font-size: 14px;
//     border: 1px solid #c4c9ce;
//     text-align: center;
//     cursor: pointer;

//     &:hover {
//       background-color: #eef1f3;
//     }
//   }

//   input {
//     display: none;
//   }
// }

// .feedback-title {
//   font-weight: bold;
//   font-size: 14px;
//   line-height: 22px;

//   &.pl {
//     color: #00A660;
//     margin-bottom: 3px;
//   }

//   &.mn {
//     color: #C50000;
//     margin-top: 14px;
//     margin-bottom: 3px;
//   }
// }