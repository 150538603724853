.api {
    border-top: 1px solid #C4C9CE;
    display: flex;
    flex-direction: column;
    height: 200px;
    margin-bottom: 50px;
    margin-left: 0px;
    margin-right: 215px;

    @media screen and (max-width: 992px) {
        margin-left: 0px;
        margin-right: 0px;
    }
}

.api__box {
    width: 100%;
}

.api__box-pm {
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    color: #6c7277;
    border-bottom: 1px solid #C4C9CE;
    border-left: 1px solid #C4C9CE;
    border-right: 1px solid #C4C9CE;
    align-items: center;

    @media screen and (max-width: 992px) {
        justify-content: space-around;
    }
}

.api__title {
    font-weight: 400;
    text-transform: uppercase;
    color: #474b4f;
    padding: 10px 0 30px;
    font-size: 1.57143rem;
}

.api__box-pm--logo {
    margin-left: 33px;
    padding-top: 10px;
    padding-bottom: 5px;
    max-width: 100px;
    width: 100%;

    img {
        max-width: 120px;
        height: 40px;

        @media screen and (max-width: 992px) {
            max-width: 105px;
            height: 35px;
        }
    }

    @media screen and (max-width: 992px) {
        margin-left: -12px;
    }
}

.api__box-pm--p1,
.api__box-pm--p2,
.api__box-pm--x {
    display: flex;
    flex-direction: column;
    align-items: center;

    .api__box-pm--p1-cof {
        color: #FF3D00;
    }
}

.api__box-pm--p1,
.api__box-pm--p2 {
    position: relative;

    &::before {
        position: absolute;
        left: -50px;
        top: 0;
        content: "";
        height: 100%;
        width: 1px;
        background: #C4C9CE;

        @media screen and (max-width: 992px) {
            left: -16px;
        }
        @media screen and (max-width: 320px) {
            left: -14%;
        }
    }

    &::after {
        position: absolute;
        right: -50px;
        top: 0;
        content: "";
        height: 100%;
        width: 1px;
        background: #C4C9CE;

        @media screen and (max-width: 992px) {
            right: -16px;
        }
        @media screen and (max-width: 320px) {
            right: -14%;
        }
    }
}

.api__box-pm--button {
    padding-right: 30px;
    margin-left: -13px;

    button {
        padding: 5px 50px;
        background: #FF3D00;
        border-radius: 2px;
        border: none;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        text-transform: uppercase;
        color: #FFFFFF;

        @media screen and (max-width: 992px) {
            font-size: 9px;
            padding: 5px 5px;
        }
    }

    @media screen and (max-width: 992px) {
        padding-right: 0px;
        margin-left: 0px;
    }
}