.article {
  &__date {
    padding-left: 22px;
    margin-bottom: 30px;
    @include text-style(normal, 32px, 37px, uppercase, $textDarkColor);

    @media screen and (max-width: 768px) {
      font-size: rem(24px);
      line-height: 28px;
    }
  }

  &__thumbnail {
    position: relative;
    margin: 0 100px 30px;
    height: 100%;
    @include resp-ratio(940, 500);
    border-radius: $radius;
    overflow: hidden;

    @media screen and (max-width: 992px) {
      margin: 0 60px 30px;
    }

    @media screen and (max-width: 768px) {
      margin: 0 0 30px;
    }
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 800px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  &__tags {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-left: 100px;
    padding-right: 100px;
    margin-bottom: 10px;
    list-style: none;

    @media screen and (max-width: 992px) {
      padding-left: 60px;
      padding-right: 60px;
    }

    @media screen and (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }

    li {
      border: 1px solid $darkColor;
      border-radius: $radius;
      align-items: center;
      margin-right: 20px;
      margin-bottom: 20px;
      padding: 4px 8px;
      @include text-style(normal, 14px, 22px, uppercase, $textDarkColor)
    }
  }
}
