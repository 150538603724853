.header__scroll {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    transition: all .3s ease;
    z-index: 5;
}

.table__aside {
    position: fixed;
    top: 0;
    right: 160px;
    bottom: 0;
    max-width: 378px;
    width: 100%;
    height: auto;
    z-index: 5;

    svg {
        width: 50px;
        height: 50px;
    }

    @media screen and (min-width: 1729px) and (max-width: 1918px) {
        right: 90px;
    }

    @media screen and (min-width: 1537px) and (max-width: 1729px) {
        right: 15px;
        max-width: 338px;
    }


    @media screen and (min-width: 1440px) and (max-width: 1537px) {
        right: 20px;
        max-width: 230px;
    }

    @media screen and (min-width: 1366px) and (max-width: 1440px) {
        right: 10px;
        max-width: 186px;
    }

    @media screen and (max-width: 1365px) {
        display: none;
    }

}

.aside__item {
    margin-top: 2px;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 65px;
    max-width: 378px;
    border: 1px solid #3F7FA4;
    border-radius: 2px;

    @media screen and (min-width: 1366px) and (max-width: 1600px) {
        max-width: 328px;
    }

    @media screen and (min-width: 968px) and (max-width: 1366px) {
        max-width: 189px;
    }

    

    h5 {
        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 22px;
        text-transform: uppercase;
        color: #000000;
        text-align: center;

        @media screen and (min-width: 1366px) and (max-width: 1475px) {
            font-size: 13px;
        }
    }

    .aside__item-img {
        background-color: #151e45;
        max-width: 81px;
        width: 100%;
        height: 49px;

        img {
            width: 100%;
            height: 100%;
        }

        @media screen and (min-width: 1366px) and (max-width: 1475px) {
            max-width: 71px;
            height: 29px;
        }
    }


    .aside__item-button {
        display: flex;
        justify-content: center;
        padding: 10px 10px;
        background: #FF3D00;
        border-radius: 2px;

        &:hover {
            background: #eb6942;
        }


        @media screen and (min-width: 1366px) and (max-width: 1475px) {
            padding: 5px 5px;
        }

        a {
            font-family: 'Roboto Condensed';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            text-transform: uppercase;
            color: #FFFFFF;

            @media screen and (min-width: 1366px) and (max-width: 1475px) {
                font-size: 11px;
            }
        }
    }
}

.aside__header {
    display: flex;
    align-items: center;
    max-width: 378px;
    width: 100%;
    height: 42px;
    background: #3F7FA4;
    border-radius: 0px 0px 2px 2px;

    @media screen and (min-width: 1366px) and (max-width: 1475px) {
        max-width: 230px;
    }

    p {
        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 700;
        margin: 0 auto;
        font-size: 18px;
        line-height: 144%;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
    }
}

.aside__banner {
    display: flex;
    flex-direction: column;
    margin-top: 2px;

    img {
        width: 100%;

        @media screen and (min-width: 1366px) and (max-width: 1475px) {
            max-width: 230px;
        }
    }

    .aside__banner-block {
        position: relative;
    }

    .aside__text {
        position: absolute;
        bottom: 95px;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 2px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;

        span {
            margin-top: 8px;
            font-family: 'Roboto Condensed';
            font-weight: 700;
            font-size: 30px;
            color: #F8FF13;
            letter-spacing: 0px;
        }
    }

    .aside__ref {
        position: absolute;
        bottom: 31px;
        width: 100%;
        display: flex;
        justify-content: center;

        p {
            max-width: 266px;
            width: 100%;
            height: 44px;
            background: #FF3D00;
            border-radius: 2px;
            justify-content: center;
            display: flex;
            align-items: center;
            font-family: 'Roboto Condensed';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            text-transform: uppercase;
            color: #FFFFFF;

            &:hover {
                background: #eb6942;
            }
        }
    }
}

.header__bonus {
    cursor: pointer;


    &:hover {
        opacity: 0.8;
    }

    @media screen and (max-width: 968px) {
        position: absolute;
        right: 60px;
        top: 12px;
    }
}

.header__bonus-block {
    position: relative;
    display: none;
    width: 400px;
    margin: auto;
    border: 1px solid #ccc;
    overflow: hidden;
    height: 70px;
}

.header__bonus-block .header__bonus {
    //display: block;
    width: 100%;
    height: 70px;
}

.visible {
    height: auto;
    display: block;
    position: absolute;
    right: 13px;
    top: 74%;
    z-index: 2;

    @media screen and (max-width: 992px) {
        right: 44px;
        top: 88%;
    }
}

.header__bonus-block {
    border: none;
    max-width: 165px;
    width: 100%;
}

.header__bonus-box {
    padding: 12px 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    img {
        width: 117px;
        height: 54px;
    }

    p {
        margin-top: 8px;
        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        color: #000000;
    }

    a {
        margin-top: 8px;
        width: 117px;
        height: 26px;
        background: #FF3D00;
        border-radius: 2px;
        display: flex;
        align-items: center;

        &:hover {
            background: #eb6942;
        }

        p {
            font-family: 'Roboto Condensed';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            margin: 0 auto;

            text-transform: uppercase;
            color: #FFFFFF;
        }
    }
}

.poster__body {
    position: relative;
}

.poster__button-block {
    position: absolute;
    right: 16px;
    bottom: 16px;
    display: flex;
}

.poster__button-right-block-p {
    // display: flex;
    // flex-direction: column;
}

.poster__button-left {
    width: 168px;
    height: 44px;
    background-color: #00a660;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    cursor: pointer;

    .hovers__block-p-p {
        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        cursor: pointer;
    }

    p {
        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        cursor: pointer;
    }
}

.poster__button-right {
    width: 168px;
    height: 44px;
    background: #FF3D00;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.poster__button-right-block {
    display: flex;
    justify-content: space-between;

    img {
        height: 27px;
        width: 30px;
        margin-right: 10px;
        margin-top: 5px;
    }
}

.poster__button-right-block-p {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;

    span {
        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 400;
        font-size: 9px;
        line-height: 14px;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
    }
}

.hovers__block {
    padding-top: 50px;
    position: relative;
    display: none;
    position: absolute;
    top: 100%;
    right: -112%;
    width: 399px;
    height: 225px;


    @media screen and (max-width: 968px) {
        height: 255px;
        right: -94%;
        width: 329px;
    }

    &::before {
        content: "";
        position: absolute;
        background: url('./images/bordall.png');
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        top: 19px;
        bottom: 0;
        z-index: -1;


        @media screen and (max-width: 968px) {
            background: url('./images/bordallmob.png');
            background-repeat: no-repeat;
            height: 285px;
            width: 353px;
        }
    }
}

.hovers {
    position: relative;
    z-index: 1;
}

.hovers:hover .hovers__block {
    display: block;
}

.hovers__block-p {
    max-width: 281px;
    width: 100%;
    margin: 3px auto;
    position: relative;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    color: #6C7074;


    &::before {
        content: "";
        position: absolute;
        left: -10px;
        bottom: -20px;
        width: 110%;
        background-color: #EEF1F3;
        height: 1px;
    }
}

.hovers__block-items {
    margin-top: 35px;
    display: flex;
    justify-content: space-around;

    @media screen and (max-width: 968px) {
        flex-direction: column;
        justify-content: space-around;
        width: 340px;
        padding: 5px 30px;
        margin: 0 auto;
        padding-top: 35px;
    }
}

.hovers__block-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 968px) {
        flex-direction: row;
        justify-content: center;
        padding: 5px 0px;
        width: 100%;
    }

    img {
        width: 66px;
        height: 41px;
        background-color: #151e45;
    }

    p {
        margin-top: 8px;
        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 22px;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        color: #000000;
        width: 100%;
    }


    a {
        margin-top: 8px;
        width: 101px;
        height: 26px;
        background-color: #FF3D00;
        border-radius: 2px;

        &:hover {
            background: #eb6942;
        }

        p {
            font-family: 'Roboto Condensed';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 12px;
            justify-content: center;
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: uppercase;
            color: #FFFFFF;

            @media screen and (max-width: 968px) {
                padding: 0 13px;
            }
        }
    }
}

.banner__butosnov-block {
    margin-top: 100px;
    height: 91px;
    max-width: 1164px;
    width: 100%;
    position: fixed;

    bottom: -6px;
    z-index: 10;

    @media screen and (max-width: 968px) {
        left: 0;
        bottom: -3px;
    }
}

.butosno {
    position: relative;
}

.banner__butosnov-box {
    max-width: 1164px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 968px) {
        flex-direction: column;
        height: 88px;
        justify-content: center;
    }
}

.banner__butosnov-button {
    display: flex;
    align-items: center;
}

.banner__butosnov-zif {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;

    span {
        font-size: 28px;
        line-height: 33px;
        color: #FF3D00;
    }
}


@media screen and (max-width: 968px) {

    .banner__butosnov-logo,
    .banner__butosnov-reiting,
    .banner__butosnov-zif {
        display: none;
    }
}

.banner__butosnov-reiting {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    align-items: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

.banner__butosnov-logo {
    margin: 0px 30px;
    min-height: 65px;

    img {
        max-width: 150px;
        width: 100%;
        height: 100%;
    }
}



.banner__butosnov-button {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
}

.banner__butosnov-button-left {
    color: #FFFFFF;
    margin-right: 24px;
    width: 190px;
    height: 44px;
    background: #FF3D00;
    border-radius: 2px;
    display: flex;
    align-items: center;

    p {
        margin: 0 auto;
    }

    @media screen and (max-width: 968px) {
        width: 130px;
        height: 44px;
        margin-right: 8px;
    }
}

.banner__butosnov-button-right {
    color: #FFFFFF;
    margin-right: 35px;
    width: 190px;
    height: 44px;
    background: #00A660;
    border-radius: 2px;
    display: flex;
    align-items: center;

    p {
        margin: 0 auto;
    }

    @media screen and (max-width: 968px) {
        width: 130px;
        height: 44px;
        margin-right: 0px;
        margin-left: 8px;
    }
}

.bonus-item-box {
    display: flex;
    justify-content: space-around;

    @media screen and (max-width: 968px) {
        flex-direction: column;
        width: 100%;
    }
}

.bonus-item {
    margin: 10px auto;
}

.banner__buttonpm-logo {
    max-width: 200px;
    width: 100%;
    margin-left: 50px;

    @media screen and (max-width: 968px) {
        max-width: 100px;
        width: 100%;
        margin-left: 0px;
    }

    img {
        max-width: 220px;
        width: 100%;
        height: 100%;

        @media screen and (max-width: 968px) {
            max-width: 100px;
            width: 100%;
            margin-left: 5px;
        }
    }
}

.banner__buttonpm-block {
    margin-top: 100px;
    height: 91px;
    max-width: 1164px;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 10;

    @media screen and (max-width: 968px) {
        left: 0;
        height: 85px;
        bottom: 0;
        margin-top: 0px;
    }
}

#banner__buttonpm-img {
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 11;
}

.banner__buttonpm-box {
    max-width: 1164px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;

    @media screen and (max-width: 968px) {
        height: 85px;
    }
}

.banner__buttonpm-text {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-left: 100px;

    @media screen and (max-width: 968px) {
        margin-left: 0px;
    }
}

.banner__buttonpm-bon {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 45px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    @media screen and (max-width: 968px) {
        margin-bottom: 5px;
        font-size: 24px;
    }

    span {
        font-size: 18px;
        line-height: 21px;
        color: #FFFFFF;

        @media screen and (max-width: 968px) {
            display: none;
        }
    }
}

.banner__buttonpm-button {
    max-width: 293px;
    width: 100%;
    height: 56px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    margin-right: 40px;

    @media screen and (max-width: 968px) {
        margin-right: 10px;
        max-width: 139px;
        height: 30px;
    }

    P {
        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 22px;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        color: #000000;
        margin: 0 auto;

        @media screen and (max-width: 968px) {
            font-size: 17px;
        }
    }
}

@media screen and (max-width: 968px) {

    .banner__buttonpm-text,
    .banner__buttonpm-butto {
        display: none;
    }

    .poster__rating,
    .poster__logo {
        height: 88px;
        width: 144px;
        margin-bottom: 10px;
    }
}


@media screen and (max-width: 968px) {
    .styled-text .read-more-btn {
        position: absolute;
        bottom: 115px;
        width: 130px;
    }
}