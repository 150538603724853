.custom_breadcrumbs {
  text-align: left;
  padding: 15px 0;

  a,
  span {
    text-decoration: none;
    @include text-style(bold, $fontSize, 22px, uppercase, $textColor);
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  .kb_title {
    color: $textBlackColor;
  }
}
