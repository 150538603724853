.faq {

  &__title {
    padding: 100px 20px 30px;
    @include text-style(normal, 32px, 37px, uppercase, $textDarkColor);

    @media screen and (max-width: 768px) {
      padding: 50px 0 30px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 100px;
    margin-bottom: 31px;

    @media screen and (max-width: 992px) {
      padding: 0 60px;
    }

    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }

  &__item {
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.faq-item {
  width: 100%;
  padding: 30px;
  border: 1px solid $greyColor;
  border-radius: $radius;
  cursor: pointer;

  &:hover {
    .faq-item__header {
      &::after {
        background-color: $darkHoverColor;
      }
    }
  }

  &.active {
    .faq-item__header {
      &::after {
        background-image: $minus;
      }
    }
  }

  &__header {
    position: relative;
    padding-right: 40px;
    @include text-style(bold, 18px, 26px, initial, $textColor);

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 26px;
      height: 26px;
      border-radius: $radius;
      background: $darkColor $plus center no-repeat;
      transition: all .3s;
    }
  }

  &__body {
    display: none;
    margin-top: 25px;
  }
}
