.list {
  padding-bottom: 60px;

  &.with-pagination {
    padding-bottom: 0;

    .list__body {
      padding-bottom: 40px;
    }
  }

  &.pt {
    //padding-bottom: 0;
    padding-top: 60px;
  }

  &__body {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }

  &__button {
    margin: 40px auto 0;
    min-width: 280px;
  }
}
