.table__mobile {
  display: none;
  margin-bottom: 50px;
  width: 115%;
}

@media screen and (max-width: 992px) {
  .table__mobile {
    display: block;
  }
}

.single__table-box-block {
  margin-bottom: 50px;
}

@media screen and (max-width: 992px) {
  .single__table-box-block {
    display: none;
  }
}

.single__acardion {
  margin: 50px 0px;
}

.single__acardion-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.single__acardion-plus {
  width: 49%;
  border: 1px solid #C4C9CE;

  @media screen and (max-width: 992px) {
    width: 100%;
  }
}

.acardion-plus__header p {
  background-color: #000000;
  padding: 12px 0px 12px 20px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #FFFFFF;
}

.plus__atem {
  position: relative;
  padding: 16px 0px 16px 58px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #6B7286;
  border-top: 1px solid #C4C9CE;
}

.plus__atem::before {
  position: absolute;
  content: "";
  top: 35%;
  left: 20px;
  width: 20px;
  height: 20px;
  background-image: url(./images/plus.svg);
  background-repeat: no-repeat;
}

.single__acardion-minus {
  width: 49%;
  border: 1px solid #C4C9CE;

  @media screen and (max-width: 992px) {
    width: 100%;
    margin-top: 20px;
  }
}

.acardion-minus__header p {
  background-color: #000000;
  padding: 12px 0px 12px 20px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #FFFFFF;
}

.minus__atem {
  position: relative;
  padding: 16px 0px 16px 58px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #6B7286;
  border-top: 1px solid #C4C9CE;
}

.minus__atem::before {
  position: absolute;
  content: "";
  top: 45%;
  left: 20px;
  width: 20px;
  height: 20px;
  background-image: url(./images/min.svg);
  background-repeat: no-repeat;
}

// Table
.single__table-box {
  border: 1px solid #C4C9CE;
  display: flex;
  justify-content: space-between;
}

.table__column1,
.table__column2,
.table__column3,
.table__column4 {
  width: 25%;
}

@media screen and (max-width: 992px) {

  .table__column1,
  .table__column2,
  .table__column3,
  .table__column4 {
    width: 100%;
  }
}


.table__column1 p {
  padding: 16px 0px 16px 20px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: #000000;
  border-top: 1px solid #C4C9CE;
}


@media screen and (max-width: 992px) {
  .table__column1 p {
    padding: 16px 0px 16px 10px;
    width: 286px;
  }
}

@media screen and (max-width: 992px) {
  .table__column1 {
    position: absolute;
  }
}

.table__column2 p,
.table__column3 p,
.table__column4 p {
  padding: 16px 0px 16px 20px;
  position: relative;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #00A660;
  border-top: 1px solid #C4C9CE;
  border-left: 1px solid #C4C9CE;
}

@media screen and (max-width: 992px) {

  .table__column2 p,
  .table__column3 p,
  .table__column4 p {
    padding: 16px 0px 16px 200px;
  }
}

.table__column2 pre,
.table__column3 pre,
.table__column4 pre {
  text-align: center;
  padding: 16px 0px 15px 35px;
  position: relative;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: #3F7FA4;
  border-left: 1px solid #C4C9CE;
}

@media screen and (max-width: 992px) {

  .table__column2 pre,
  .table__column3 pre,
  .table__column4 pre {
    padding: 16px 0px 15px 10px;
  }
}

.table__column2 pre::before {
  position: absolute;
  content: "";
  left: 60px;
  width: 30px;
  height: 30px;
  background-image: url(./images/tab1.svg);
  background-repeat: no-repeat;
}

@media screen and (max-width: 992px) {
  .table__column2 pre::before {
    position: absolute;
    content: "";
    left: 60px;
    width: 30px;
    height: 30px;
    background-image: url(./images/tab1.svg);
    background-repeat: no-repeat;
  }
}



.table__column3 pre::before {
  position: absolute;
  content: "";
  left: 70px;
  width: 30px;
  height: 30px;
  background-image: url(./images/tab2.svg);
  background-repeat: no-repeat;
}

@media screen and (max-width: 992px) {
  .table__column3 pre::before {
    position: absolute;
    content: "";
    left: 65px;
    width: 30px;
    height: 30px;
    background-image: url(./images/tab2.svg);
    background-repeat: no-repeat;
  }
}

.table__column4 pre::before {
  position: absolute;
  content: "";
  left: 45px;
  width: 30px;
  height: 30px;
  background-image: url(./images/tab3.svg);
  background-repeat: no-repeat;
}

@media screen and (max-width: 992px) {
  .table__column4 pre::before {
    position: absolute;
    content: "";
    left: 57px;
    width: 30px;
    height: 30px;
    background-image: url(./images/tab3.svg);
    background-repeat: no-repeat;
  }
}

.table__slider {
  width: 100%;
}


.post-slider__arrow {
  position: absolute;
  top: 0;
  background: none;
  border: none;
  font-size: 30px;
}

.post-slider__prev {
  z-index: 2;
  left: 0;
  top: 16px;
  margin-left: 20px;
}

.post-slider__next {
  right: 0;
  top: 16px;
  margin-right: 20px;
}