.rating {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 290px;
  
    @media screen and (max-width: 544px) {
      &.sm {
        min-width: 125px;
  
        .rating__container {
          max-width: 90px;
        }
      }
    }
  
    &__container {
      max-width: 110px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
    }
  
    &__stars {
      position: relative;
      padding-top: 20%;
      width: 100%;
      display: block;
  
      &_all {
        position: absolute;
        width: 200%;
        height: 100%;
        top: 0;
        overflow: hidden;
        background: url('images/star.svg') left top/auto 200% repeat-x;
      }
  
      &_current {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        overflow: hidden;
        z-index: 1;
        background: url('images/star.svg') left bottom/auto 200% repeat-x;
      }
    }
  
    &__text {
      margin-left: 117px;
    //   font-family: $secondaryFont;
    //   @include text-style(bold, 14px, 1.5, uppercase, $textYellow);
    }
  }
  