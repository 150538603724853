.bonus-item {
  display: flex;
  flex-direction: column;
  border: 1px solid $greyColor;
  border-radius: $radius;
  background-color: $whiteColor;
  overflow: hidden;

  &__top {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
  }

  &__info {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 20px;
    height: 260px;
    background: $greyColor url('./images/bonus-poster_mob.jpg') center/cover no-repeat;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    img {
      width: auto;
      max-height: 60px;
    }

    span {
      user-select: none;
    }

    &-name {
      @include text-style(bold, 22px, 36px, uppercase, $textWhiteColor);
    }

    &-num {
      @include text-style(bold, 42px, 36px, uppercase, $textYellowColor);
      display: block;
     
    }
  }

  &__content {
    flex-grow: 1;
  }

  &__text {
    padding: 0 20px 20px;
    @include text-style(bold, 14px, 22px, initial, $darkColor);
  }

  &__bottom {
    width: 100%;
    padding: 0 20px 20px;
  }

  &__button {
    width: 100%;
  }
}