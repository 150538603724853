.btn {
  position: relative;
  display: block;
  min-width: 128px;
  width: fit-content;
  padding: 5px 25px;
  text-align: center;
  border-radius: $radius;
  border: 1px solid $accentColor;
  background: $accentColor;
  @include text-style(bold, 18px, 22px, uppercase, $textWhiteColor);
  transition: all .3s;
  cursor: pointer;

  &:hover,
  &:active {
    border-color: $accentHoverColor;
    background: $accentHoverColor;
  }

  &_o {
    border-color: $darkColor;
    background: transparent;
    color: $darkColor;

    &:hover,
    &:active {
      border-color: $darkColor;
      background: $greyColor;
    }
  }

  &.sm {
    min-width: 105px;
    font-size: rem(14px);
  }

  &.bonus {
    margin: 40px auto 0;
    min-width: 280px;

    @media screen and (max-width: 544px) {
      min-width: 100%;
    }
  }
}


.button-otz {
  color: #ffff !important;
  text-decoration: none;
  background: #3f7fa4;
  border: #3f7fa4;
  padding: 5px 70px;
  text-align: center;
  min-width: 128px;
  margin: 60px auto;

  &:hover {
    text-decoration: none !important;
    background: #4996c2 !important;
  }
}



.star-button-top,.aside__item .aside__item-button,
.btn {
	border-radius: 10px;}

.table__button_ref {
  border-top: 1px solid #c4c9ce;
  min-width: 113px;
  padding: 1px 12px;
  line-height: 31px;
  font-size: 1rem;
  margin-top: 0px;
}

@media screen and (max-width: 968px) {
  .table__button_ref {
    border-top: 1px solid #c4c9ce;
    min-width: 80px;
    border-radius: 0;
  }
  .star-button-top,.aside__item .aside__item-button,
.btn {
	border-radius: 0px;}
}