.poster {
  width: 100%;
  height: 240px;
  margin-bottom: 30px;
  background: $greyColor url('./images/default-poster.jpg') center/cover no-repeat;
  border-radius: $radius;
  //overflow: hidden;

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    padding: 20px;
  }

  &__logo {
    margin-bottom: 10px;

    img {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 150px;
      vertical-align: top;
    }
  }

  &__rating {
    text-align: center;
    @include text-style(normal, 28px, 33px, uppercase, $textGreyColor);

    strong {
      color: $textWhiteColor;
    }
  }

  &__name {
    @include text-style(bold, 36px, 36px, uppercase, $textWhiteColor);

    @media screen and (max-width: 768px) {
      font-size: rem(22px);
    }
  }

  &__num {
    @include text-style(bold, 84px, 84px, uppercase, $textYellowColor);

    @media screen and (max-width: 768px) {
      font-size: rem(42px);
      line-height: 36px;
    }
  }

  &_bonus {
    width: auto;
    margin: 0 100px 30px;
    max-width: 945px;
    background-image: url('./images/bonus-poster.jpg');

    @media screen and (max-width: 992px) {
      margin: 0 60px 30px;
    }

    @media screen and (max-width: 768px) {
      margin: 0 0 30px;
    }

    @media screen and (max-width: 544px) {
      background-image: url('./images/bonus-poster_mob.jpg');
    }

    .poster__body {
      align-items: flex-start;
      padding: 10px 20px;

      @media screen and (max-width: 768px) {
        padding: 20px;
      }
    }

    .poster__logo {
      img {
        width: auto;
        max-height: 85px;
      }
    }
  }
}
