.burger {
  flex-direction: column;
  justify-content: center;
  width: 25px;
  cursor: pointer;
  height: auto;

  &__line {
    position: relative;
    display: block;
    height: 5px;
    width: 100%;
    border-radius: $radius;
    overflow: hidden;
    background-color: $whiteColor;
    transition: all .3s;

    &_top {
      top: 0;
    }

    &_middle {
      margin: 5px 0;
    }

    &_bottom {
      bottom: 0;
      margin-bottom: 0;
    }
  }

  &_active {

    .burger__line {
      background-color: $whiteColor;

      &_top {
        transform: rotate(45deg);
        top: 10px;
      }

      &_middle {
        opacity: 0;
      }

      &_bottom {
        transform: rotate(-45deg);
        bottom: 10px;
      }
    }
  }
}
