.last {
  padding-top: 60px;

  &__header {
    margin-bottom: 30px;

    h2 {
      @include text-style(normal, 32px, 37px, uppercase, $textDarkColor);

      @media screen and (max-width: 768px) {
        font-size: rem(22px);
        line-height: 30px;
      }
    }
  }

  &__body {
    padding-left: 100px;
    padding-right: 100px;

    @media screen and (max-width: 992px) {
      padding-left: 60px;
      padding-right: 60px;
    }

    @media screen and (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__list {
    list-style: none;
  }

  &__item {
    position: relative;
    padding-left: 35px;
    margin-bottom: 30px;

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 20px;
      height: 20px;
      border-radius: $radius;
      transform: translateY(-50%);
      background: $mainColor;
    }
  }

  &__link {
    text-decoration: none;
    @include text-style(normal, 24px, 28px, initial, $textDarkColor);

    &:hover {
      color: $mainColor;
    }

    @media screen and (max-width: 768px) {
      font-size: rem(16px);
      line-height: 18px;
    }
  }
}
