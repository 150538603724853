.table {
  padding-bottom: 60px;

  &__header {
    margin-bottom: 15px;
    padding: 6px 0;
  }

  .table-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    text-align: center;
    background-color: $mainColor;
    border-radius: $radius;
    @include text-style(normal, 12px, 16px, uppercase, $textWhiteColor);

    @media screen and (max-width: 992px) {
      display: none;
    }

    &__logo {
      min-width: 230px;
    }

    &__rating {
      min-width: 130px;
    }

    &__bonus {
      min-width: 255px;
    }

    &__review {
      min-width: 85px;
    }

    &__apps {
      min-width: 100px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    transition: all .3s;

    @media screen and (max-width: 992px) {
      display: grid;
      grid-gap: 30px;
      grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
      align-items: stretch;
      justify-content: flex-start;
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100px;
    min-height: 100px;
    margin-bottom: 15px;
    border-radius: $radius;
    overflow: hidden;

    &:last-of-type {
      margin-bottom: 0;
    }

    @media screen and (max-width: 992px) {
      flex-direction: column;
      height: auto;
      margin-bottom: 0;
    }
  }

  &__title_mobile {
    display: none;
    width: 100%;
    background-color: $darkHoverColor;
    padding: 2px;
    text-align: center;
    @include text-style(normal, 12px, 16px, uppercase, $textWhiteColor);

    @media screen and (max-width: 992px) {
      display: block;
    }
  }

  &__logo {
    position: relative;
    min-width: 230px;
    height: 100%;
    padding: 5px;

    @media screen and (max-width: 992px) {
      width: 100%;
      height: 100px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__position {
    position: absolute;
    top: 0;
    left: 0;
    width: 25px;
    height: 25px;
    background-color: $darkColor;
    border-radius: $radius 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @include text-style(bold, 18px, 21px, uppercase, $textWhiteColor);
  }

  &__rating {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 130px;
    height: 100%;
    border: 1px solid $greyColor;
    border-left: none;
    @include text-style(normal, 28px, 33px, uppercase, $greyColor);

    @media screen and (max-width: 992px) {
      width: 100%;
      height: auto;
      padding: 6px;
      border-left: 1px solid $greyColor;
      border-top: none;
    }

    strong {
      color: $textDarkColor;
    }
  }

  &__bonus {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 255px;
    height: 100%;
    border: 1px solid $greyColor;
    border-left: none;
    padding: 10px;

    @media screen and (max-width: 992px) {
      width: 100%;
      border-left: 1px solid $greyColor;
      border-top: none;
    }
  }

  &__bonus-title {
    @include text-style(normal, 14px, 22px, uppercase, $textDarkColor);
  }

  &__bonus-value {
    @include text-style(bold, 30px, 22px, uppercase, $textBlackColor);
  }

  &__bonus-button {
    margin-top: 4px;
  }

  &__review {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 85px;
    height: 100%;
    border: 1px solid $greyColor;
    border-left: none;
    padding: 10px;
    cursor: pointer;

    @media screen and (max-width: 992px) {
      width: 100%;
      height: auto;
      border-left: 1px solid $greyColor;
      border-top: none;
      order: 1;
    }
  }

  &__review-title {
    display: none;
    padding-right: 7px;
    @include text-style(normal, 14px, 22px, uppercase, $textColor);

    @media screen and (max-width: 992px) {
      display: inline-block;
    }
  }

  &__review-img {
    display: inline-block;
    width: 30px;
    height: 24px;
    background: transparent $comments center no-repeat;
  }

  &__apps {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    height: 100%;
    border: 1px solid $greyColor;
    border-left: none;
    padding: 10px;

    @media screen and (max-width: 992px) {
      width: 100%;
      height: auto;
      border-left: 1px solid $greyColor;
      border-top: none;
    }
  }

  &__apps-android,
  &__apps-ios {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: $radius;
    margin: 4px;
    background-color: $darkColor;
    transition: all .3s;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      background-color: $darkHoverColor;
    }

    @media screen and (max-width: 992px) {
      width: 48px;
      height: 48px;
    }
  }

  &__apps-android {
    &::after {
      background: transparent $android center no-repeat;
      display: inline-block;
      width: 24px;
      height: 13px;

      @media screen and (max-width: 992px) {
        background-image: $android_big;
        width: 40px;
        height: 22px;
      }
    }
  }

  &__apps-ios {
    &::after {
      background: transparent $apple center no-repeat;
      display: inline-block;
      width: 19px;
      height: 23px;

      @media screen and (max-width: 992px) {
        background-image: $apple_big;
        width: 30px;
        height: 36px;
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    border: 1px solid $greyColor;
    border-left: none;
    padding: 10px;

    @media screen and (max-width: 1120px) {
      flex-wrap: wrap;
    }

    @media screen and (max-width: 992px) {
      width: 100%;
      height: auto;
      border-left: 1px solid $greyColor;
      border-top: none;
      padding: 20px;
      order: 2;
    }
  }

  &__button {
    width: 100%;

    &_ref {
      width: 100%;
      margin-left: 10px;

      @media screen and (max-width: 1120px) {
        margin-left: 0;
      }

      @media screen and (max-width: 992px) {
        margin-top: 0px;
      }
    }
  }

  &__more-button {
    margin: 25px auto 0;
    min-width: 280px;
  }
}



.table-header-mob {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  text-align: center;
  background-color: $mainColor;
  border-radius: $radius;
  display: none;
  @include text-style(normal, 12px, 16px, uppercase, $textWhiteColor);

  @media screen and (max-width: 992px) {

    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  &__logo {
    min-width: 88px;
  }

  &__rating {
    min-width: 88px;
  }

  &__bonus {
    min-width: 94px;
  }

  &__review {
    min-width: 85px;
  }

  &__apps {
    min-width: 100px;
  }
}



