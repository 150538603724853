.header {
  width: 100%;

  &__body {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $mainColor;
    padding: 25px 30px;
    border-radius: $radius;
    border-bottom: 4px solid $mainDarkColor;

    @media screen and (max-width: 768px) {
      padding: 15px 22px;
    }
  }

  &__logo {
    max-width: 165px;

    @media screen and (max-width: 768px) {
      max-width: 110px;
    }

    img {
      width: 100%;
      vertical-align: top;
    }
  }

  &__menu {
    padding-left: 0px;

    @media screen and (max-width: 992px) {
      display: none;
      padding-left: 0;
      z-index: 10;
    }
  }

  &__burger {
    display: none;

    @media screen and (max-width: 992px) {
      display: block;
    }
  }

  &__search {
    // padding: 0 50px;

    @media screen and (max-width: 1238px) {
      //padding: 0 30px;
    }

    @media screen and (max-width: 992px) {
      order: 1;
      //padding: 0 0 25px;
      width: 100%;
    }
  }
&__.search-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  left: 0;
  right: 95px;
  opacity: 0;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: right;
  transform-origin: right;
  visibility: hidden;
  -webkit-transition: all 1s, opacity .5s 0s;
  transition: all 1s, opacity .5s 0s;
}


  &__search-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url(./images/search.svg);
    background-repeat: no-repeat;
    background-position: center;
    vertical-align: middle;
    cursor: pointer;


    &:hover{
      opacity: 0.6;
    }

    @media screen and (max-width: 992px) {
      display: none;
    }
  }
}

.scale-hide {
  -webkit-transition: all .3s;
  transition: all .3s;
  -webkit-transform: scale(0);
  transform: scale(0)
}

.scale-show {
  -webkit-transition: all .3s .5s;
  transition: all .3s .5s;
  -webkit-transform: scale(1);
  transform: scale(1)
}
