.post-item {
  display: flex;
  flex-direction: column;
  border: 1px solid $greyColor;
  border-radius: $radius;
  background-color: $whiteColor;
  overflow: hidden;

  &__top {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 20px 10px;
  }

  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;

    &-label {
      @include text-style(normal, 14px, 22px, uppercase, $textDarkColor);
    }

    &-name {
      margin-bottom: 10px;
      @include text-style(bold, 22px, 22px, uppercase, $textBlackColor);
    }

    &-rating {
      @include text-style(normal, 28px, 33px, uppercase, $textGreyColor);

      strong {
        color: $textDarkColor;
      }
    }
  }

  &__text {
    @include text-style(normal, 14px, 22px, initial, $textColor);
  }

  &__img {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 5px;
    max-height: 80px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-height: 80px;
    }
  }

  &__bottom {
    width: 100%;
    padding: 0 20px 20px;
  }

  &__button {
    width: 100%;
  }
}
