.search-form {
  display: flex;
  position: absolute;
  right: 95px;
  opacity: 0;
  align-items: center;
  transform: scaleX(0);
  transform-origin: right;
  visibility: hidden;
  transition: all 1s, opacity .5s 0s;

  @media screen and (max-width: 992px) {
    position: static;
    transform: scaleX(1);
    visibility: visible;
    opacity: 1;
    right: 77px;
  }

  &.active {
    transform: scaleX(1);
    visibility: visible;
    opacity: 1;
    transition: all 1s, opacity 1s .5s;
   
  }

  &__button {
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: none;
  }

  &__input {
    width: 810px;
    padding-left: 5px;
    margin: 0 15px;
    background-color: transparent;
    border: none;
    color: white;
    border-bottom: 2px solid rgb(255, 255, 255);
  }

  &__input::placeholder {
  color: white;
  }

  &__close {
    position: relative;
    width: 20px;
    height: 20px;
    background: transparent;
    border: none;
    cursor: pointer;

    &:hover{
      opacity: 0.6;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      height: 20px;
      width: 2px;
      background-color: White;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }

    @media screen and (max-width: 992px) {
      display: none;
    }
  }
}




.post-contentsos{

  .news-item{

    @media screen and (max-width: 720px) {
      width: 330px!important;
      margin: 10px auto!important;
    }

    @media screen and (max-width: 320px) {
      width: 277px!important;
      margin: 10px auto!important;
    }
  }

  div{
    @media screen and (max-width: 720px) {
      margin: 0 auto;
    }
  }
}