.page-404 {
  padding: 100px 0;

  @media screen and (max-width: 768px) {
    padding: 50px 0;
  }
}

.item-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  &__text {

    .code-404 {
      text-align: center;
      @include text-style(bold, 140px, 150px, uppercase, $textBlackColor);

      @media screen and (max-width: 544px) {
        font-size: 100px;
        line-height: 120px;
      }
    }

    .text-404 {
      text-align: center;
      @include text-style(bold, 30px, 37px, uppercase, $textColor);

      @media screen and (max-width: 544px) {
        font-size: 20px;
        line-height: 25px;
      }
    }
  }
}

.err-text {
  text-align: center;
  margin: 0 auto 30px;
  max-width: 500px;
  @include text-style(bold, 14px, 18px, uppercase, $textColor);
}

.err-link {
  margin: 0 auto;
  min-width: 276px;
  width: fit-content;

  @media screen and (max-width: 544px) {
    width: 100%;
  }
}
