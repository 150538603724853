.ad {
  margin-bottom: 20px;
  margin-top: 0px;

  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }

  &__body {
    display: flex;
    align-items: initial;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-height: 180px;
    overflow: hidden;
    border-radius: $radius;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      //max-height: 100px;
      height: 120px;
    }

    img {
      width: 100%;
      min-width: 100%;
      height: 100%;
      object-fit: cover;

      @media screen and (max-width: 768px) {
        //width: auto;
      }
    }
  }
}
