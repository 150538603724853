$font: 'Roboto Condensed', sans-serif;
$fontSize: 14px;

$radius: 2px;

// colors
$mainColor: #3F7FA4;
$mainHoverColor: #7BC4EF;
$mainDarkColor: #2A5F7D;

$accentColor: #FF3D00;
$accentHoverColor: #FF7246;

$darkColor: #474B4F;
$darkHoverColor: #6C7074;

$whiteColor: #FFFFFF;
$blackColor: #000000;
$greyColor: #C4C9CE;
$lightColor: #EEF1F3;

$textColor: #6C7277;
$textGreyColor: #C4C9CE;
$textDarkColor: #000000;
$textWhiteColor: #FFFFFF;
$textBlackColor: #000000;
$textYellowColor: #FFA800;

$doneColor: #00A660;
$errorColor: #C50000;

$textTransform: initial;

// adaptive text size
@function rem($size) {
  @return $size / $fontSize * 1rem;
}

// style for text
@mixin text-style($fontWeight: normal, $size: $fontSize, $lineHeight: false, $textTransform: false, $color: false) {

  @if $fontWeight {
    font-weight: $fontWeight;
  }

  @if $lineHeight {
    line-height: $lineHeight;
  }

  @if $size {
    font-size: rem($size);
  }

  @if $textTransform {
    text-transform: initial;
  }

  @if $color {
    color: $color;
  }
}

@mixin resp-ratio($x, $y, $pseudo: false) {
  $padding: unquote(($y / $x) * 74 + '%');

  @if $pseudo {
    &:before {
      @include pseudo($pos: relative);
      width: 100%;
      padding-top: $padding;
    }
  }

  @else {
    padding-top: $padding;
  }
}

// icons
$comments: url("data:image/svg+xml,%3Csvg width='30' height='24' viewBox='0 0 30 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.57142 0C3.46685 0 2.57142 0.895433 2.57142 2V17.0147L-3.40376e-06 23.1342L6.0987 20.5714H28C29.1046 20.5714 30 19.676 30 18.5714V2C30 0.895431 29.1046 0 28 0H4.57142Z' fill='%23C4C9CE'/%3E%3C/svg%3E%0A");
$android: url("data:image/svg+xml,%3Csvg width='24' height='13' viewBox='0 0 24 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.5228 9.71311C16.9713 9.71311 16.5232 9.28225 16.5232 8.75202C16.5232 8.22179 16.9713 7.79092 17.5228 7.79092C18.0743 7.79092 18.5225 8.22179 18.5225 8.75202C18.5225 9.28137 18.0743 9.71311 17.5228 9.71311ZM6.47719 9.71311C5.92569 9.71311 5.47754 9.28225 5.47754 8.75202C5.47754 8.22179 5.92569 7.79092 6.47719 7.79092C7.0287 7.79092 7.47685 8.22179 7.47685 8.75202C7.47593 9.28137 7.02778 9.71311 6.47719 9.71311ZM17.8813 3.92544L19.8788 0.599865C19.9931 0.409053 19.9255 0.164603 19.727 0.0538088C19.5285 -0.0561059 19.2743 0.00896359 19.159 0.199776L17.1368 3.56756C15.5903 2.88873 13.8534 2.51062 12.0005 2.51062C10.1475 2.51062 8.41066 2.88873 6.86407 3.56756L4.84097 0.199776C4.72573 0.00896359 4.47148 -0.0569852 4.27301 0.0538088C4.07454 0.164603 4.00595 0.408174 4.12118 0.599865L6.11867 3.92544C2.68892 5.71837 0.342975 9.05626 0 13H24C23.6561 9.05626 21.3111 5.71837 17.8813 3.92544Z' fill='%23C4C9CE'/%3E%3C/svg%3E%0A");
$android_big: url("data:image/svg+xml,%3Csvg width='40' height='22' viewBox='0 0 40 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28.8365 16.341C27.9541 16.341 27.2371 15.6516 27.2371 14.8032C27.2371 13.9548 27.9541 13.2655 28.8365 13.2655C29.7189 13.2655 30.436 13.9548 30.436 14.8032C30.436 15.6502 29.7189 16.341 28.8365 16.341ZM11.1636 16.341C10.2811 16.341 9.5641 15.6516 9.5641 14.8032C9.5641 13.9548 10.2811 13.2655 11.1636 13.2655C12.046 13.2655 12.763 13.9548 12.763 14.8032C12.7615 15.6502 12.0445 16.341 11.1636 16.341ZM29.4102 7.0807L32.6062 1.75977C32.7891 1.45447 32.6808 1.06335 32.3632 0.886082C32.0457 0.710218 31.6389 0.81433 31.4545 1.11963L28.219 6.50809C25.7445 5.42195 22.9655 4.81698 20.0008 4.81698C17.036 4.81698 14.2571 5.42195 11.7826 6.50809L8.54561 1.11963C8.36122 0.81433 7.95441 0.708811 7.63686 0.886082C7.31931 1.06335 7.20956 1.45307 7.39394 1.75977L10.5899 7.0807C5.10232 9.94938 1.34881 15.29 0.800049 21.6H39.2C38.6498 15.29 34.8978 9.94938 29.4102 7.0807Z' fill='%23C4C9CE'/%3E%3C/svg%3E%0A");
$apple: url("data:image/svg+xml,%3Csvg width='19' height='23' viewBox='0 0 19 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.0543 12.41C15.0264 9.61273 17.3435 8.25038 17.4496 8.18897C16.1375 6.27386 14.1051 6.01145 13.3905 5.9947C11.682 5.81603 10.0237 7.01646 9.15826 7.01646C8.2705 7.01646 6.93049 6.01145 5.48439 6.03936C3.62513 6.06728 1.88869 7.14487 0.933931 8.81431C-1.03142 12.2202 0.431426 17.2285 2.31861 19.9811C3.2622 21.3267 4.36213 22.8342 5.80823 22.7839C7.22083 22.7225 7.74567 21.885 9.4486 21.885C11.1348 21.885 11.6317 22.7839 13.1057 22.7504C14.6188 22.7281 15.5736 21.3937 16.4837 20.0369C17.5724 18.4903 18.0135 16.9716 18.0303 16.8879C17.9856 16.8767 15.0822 15.7656 15.0543 12.41Z' fill='%23C4C9CE'/%3E%3Cpath d='M12.2737 4.18043C13.033 3.23126 13.5523 1.94149 13.4071 0.629395C12.3072 0.679645 10.9337 1.38874 10.1464 2.31558C9.44851 3.13634 8.81759 4.47635 8.97951 5.7382C10.2134 5.82753 11.4809 5.11286 12.2737 4.18043Z' fill='%23C4C9CE'/%3E%3C/svg%3E%0A");
$apple_big: url("data:image/svg+xml,%3Csvg width='30' height='36' viewBox='0 0 30 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.687 19.056C24.6423 14.5804 28.3497 12.4006 28.5194 12.3024C26.4201 9.2382 23.1683 8.81833 22.0249 8.79153C19.2912 8.50566 16.638 10.4263 15.2533 10.4263C13.8329 10.4263 11.6889 8.81833 9.37513 8.86299C6.4003 8.90766 3.622 10.6318 2.09439 13.3029C-1.05018 18.7523 1.29038 26.7656 4.30988 31.1697C5.81962 33.3227 7.57951 35.7347 9.89326 35.6543C12.1534 35.5561 12.9932 34.216 15.7179 34.216C18.4158 34.216 19.2108 35.6543 21.5692 35.6007C23.9902 35.565 25.5178 33.4299 26.974 31.2591C28.716 28.7845 29.4217 26.3546 29.4485 26.2206C29.3771 26.2028 24.7317 24.425 24.687 19.056Z' fill='%23C4C9CE'/%3E%3Cpath d='M20.238 5.88869C21.4529 4.37001 22.2838 2.30639 22.0515 0.207031C20.2916 0.287432 18.094 1.42198 16.8344 2.90493C15.7177 4.21814 14.7082 6.36216 14.9673 8.38111C16.9416 8.52405 18.9695 7.38057 20.238 5.88869Z' fill='%23C4C9CE'/%3E%3C/svg%3E%0A");
$minus: url("data:image/svg+xml,%3Csvg width='12' height='4' viewBox='0 0 12 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='12' height='4' fill='%23C4C9CE'/%3E%3C/svg%3E%0A");
$plus: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4 8L4 12H8V8H12V4H8V0H4L4 4H0V8H4Z' fill='%23C4C9CE'/%3E%3C/svg%3E%0A");
