.knowledge-item {
  display: flex;
  flex-direction: column;
  border: 1px solid $greyColor;
  border-radius: $radius;
  background-color: $whiteColor;
  overflow: hidden;

  &__thumbnail {
    display: flex;
    flex-direction: column;
    height: 115px;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex-grow: 1;
    padding: 15px 20px 15px;
  }

  &__tags {
    border: 1px solid $darkColor;
    border-radius: $radius;
    align-items: center;
    margin-bottom: 10px;
    padding: 4px 8px;
    @include text-style(normal, 12px, 12px, uppercase, $textDarkColor);
  }

  &__title {
    @include text-style(bold, 22px, 22px, uppercase, $textBlackColor);

  }

  &__bottom {
    width: 100%;
    padding: 0 20px 20px;
  }

  &__button {
    width: 100%;
  }
}


.knowledge-imgages {
  max-height: 135px;
  width: 100%;
  padding-bottom: 15px;

  @media screen and (max-width: 992px) {
    max-height: 180px;

  }
}

.post-contentsos .news-item__title{
  font-size: 14px;
  display: flex;
  align-items: center;
}


@media screen and (max-width: 992px) {
  .knowledge-item__thumbnail{
    display: none;
  }
}
