@font-face {
  font-family: 'Roboto Condensed';
  src: local('Roboto Condensed Regular'), local('RobotoCondensed-Regular'), url('./fonts/RobotoCondensed-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'), url('./fonts/RobotoCondensed-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


