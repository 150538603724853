.menu {

  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
      padding: 5px 0;

      @media screen and (max-width: 992px) {
        padding: 5px 0;
      }

      a,
      span {
        @include text-style(bold, $fontSize, 22px, uppercase, $whiteColor);
        padding: 2px 0px;
        border-radius: $radius;
        transition: all .3s;
        width: 100%;
        text-transform: uppercase;
      }

      a {
        &:hover {
          color: $mainHoverColor;
        }
      }

      span {
        background-color: $mainHoverColor;
        cursor: context-menu;
      }
    }
  }

  &.menu_header {
    @media screen and (max-width: 992px) {
      position: absolute;
      top: 100%;
      left: 0;
      background-color: $mainColor;
      width: 100%;
      padding: 20px 0;
      border-bottom: 4px solid $mainDarkColor;
    }

    ul {
      justify-content: flex-end;
    

      @media screen and (max-width: 992px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 0 22px;
      }

      li {
        padding-left: 13px;

        @media screen and (max-width: 992px) {
          padding-left: 0;
        }

        &:first-child {
          padding-left: 0;
        }
      }
    }
  }

  &.menu_footer {
    ul {
      align-items: start;
      flex-direction: column;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }

      li {
        //padding-right: 24px;
        margin-bottom: -13px;


        @media screen and (max-width: 968px) {
          display: flex;
          text-align: center;
          width: 100%;
        }

        &:last-child {
          padding-right: 0;
        }

        a,
        span {
          font-weight: normal;
          text-transform: capitalize;
        }
      }
    }
  }

}




.topmenu>li {
  float: left;
  position: relative;
}

.topmenu>li>a {
  padding: 0px 25px;
  font-size: 12px;
  text-transform: uppercase;
  color: #FEFDFD;
  letter-spacing: 1px;
}

.topmenu>li>a.active,
.submenu a:hover {
  background: linear-gradient(90deg, #f7ff00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.submenu {
  position: absolute;
  z-index: 5;
  left: 13px;
  width: 200px;
  opacity: 0;
  display: none !important;
  // transform-origin: 0% 0%;
  // transform: rotateX(-90deg);
  //transition: .1s linear;
  padding-top: 10px;
  border: 2px solid #f7ff00;
  background-color: #3f7fa4;


  @media screen and (max-width: 768px) {
    border: 2px solid #f7ff00;
    //position: relative!important;
    left: 0px !important;
    //border: none;
    width: 100%;
    overflow: hidden;
  }
}



.submenu li {
  position: relative;
  list-style-type: none;
  padding: 5px 10px !important;
  font-size: 18px !important;


  @media screen and (max-width: 768px) {
    margin-left: 0px;
    padding: 5px 0px !important;
    height: 100% !important;
  }
}

.submenu li a {
  color: #ffffff;
  padding: 8px;
  font-size: 14px;
}

.submenu .submenu {
  position: absolute;
  left: 100%;
  top: 0px;
  transition: .1s;
  padding-left: 5px;
  padding-top: 0px;

  @media screen and (max-width: 768px) {
    // position: relative!important;
  }
}

.menu li:hover>.submenu {
  // transform: rotateX(0deg);
  //visibility: visible;
  opacity: 1;
  display: block !important;

  @media screen and (max-width: 768px) {
    position: relative !important;
  }
}


// Стрелки для меню
.topmenu li a {
  position: relative;
}

.topmenu li a:after {
  color: rgb(255, 255, 255);
  content: ' ▾';
}

.topmenu li a:hover:after {
  color: rgb(255, 255, 255);
  content: ' ▾';
}

.topmenu li a:only-child:after {
  content: '';
}