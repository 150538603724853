.page__author-home{
  margin-bottom: 84px;
}

.autor, .tags__img img{
  max-width: 156px;

  @media screen and (max-width: 768px){
    max-width: 275px;
  }
}

.autor, .tags__title{
  text-align: center;
  color: #474b4f;
  padding-top: 71px;
  margin-top: -33px;
}

.page__author {
  display: flex;
  font-family: 'Roboto Condensed';

  .author-description-texMob {
    display: none;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;

    .author-description-text {
      display: none;
    }

    .author-description-texMob {
      display: block;
      font-family: 'Roboto Condensed';
      font-size: 18px;
      font-weight: 500;
      line-height: 21px;
      margin-bottom: 10px;
    }
  }
}

.page__author-description {
  margin-left: 24px;

  @media screen and (max-width: 768px) {
    margin-left: 0px;
  }
}

.author-description-text {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.page__author-description p {
  margin-top: 16px;
  font-family: 'Roboto Condensed';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.page__author-all {
  background: #ecf2f6;
  margin-top: 25px;

}

.page__author-box {
  text-align: center;
  padding: 30px 72px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    padding: 1em;
  }

}

.author-box-itemBlue {
  min-width: 292px;
  padding: 16px 13px;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #2A5F7D;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #474B4F;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 5%;
  }

  p {
    position: relative;
    font-size: 14px;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      bottom: -3px;
      height: 1px;
      width: 100%;
      background-color: #2a5f7d;
      margin: 0 auto;
    }
  }

  span {
    font-size: 18px;
    padding-top: 11px;
    color: #2a5f7d;
  }
}

.author-box-itemGreen {
  min-width: 292px;
  padding: 16px 13px;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #00A660;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #474B4F;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 5%;
  }

  p {
    position: relative;
    font-size: 14px;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      bottom: -3px;
      height: 1px;
      width: 100%;
      background-color: #00A660;
      margin: 0 auto;
    }
  }

  span {
    font-size: 18px;
    padding-top: 11px;
    color: #00A660;
  }
}

.author-box-itemRed {
  min-width: 292px;
  padding: 16px 13px;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #FF3D00;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #474B4F;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  p {
    position: relative;
    font-size: 14px;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      bottom: -3px;
      height: 1px;
      width: 100%;
      background-color: #FF3D00;
      margin: 0 auto;
    }
  }

  span {
    font-size: 18px;
    padding-top: 11px;
    color: #FF3D00;
  }
}


.prognozy__author a{
  color: #474B4F;
}