.post-item__text {
    display: none;
}

.copyright {
    display: flex;
   // border-radius: 4px;
    padding: 6px 2px 2px 5px;
    margin-bottom: 8px;
}

.accordion {
    background: white;
    color: #000;
    cursor: pointer;
    padding: 6px 2px 0px 5px;
    width: 182px;
    //border-radius: 4px;
    text-align: center;
    outline: none;
    font-size: 14px;
    transition: 0.5s;
}

.accordion:after {
    content: '\25B2';
    color: #000;
    font-size: 10px;
    font-weight: bold;
    margin-top: 2px;
    margin-left: 5px;
}

.active:after {
    content: "\25BC";
}
.accordion__map{
    margin-top: 5px;
}
.panel {
    margin-top: -217px;
    position: relative;
   // border-top-left-radius: 6px;
    //border-top-right-radius: 6px;
    padding: 0 14px;
    color: #fff;
    background-color: #fff;
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height .3s ease-out;
    transition: max-height .3s ease-out;
    text-align: center;
}

.panel div {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    color: #404040;
}

.panel div a {
    color: #404040;
    text-decoration: none;
}

.panel div a:hover {
    color: #3f7fa4;
    font-size: 13px;
}

.styled-text {
    font-size: 1.1rem;
}



.the_champ_sharing_ul {
    list-style-type: none;
}

.form-submit {
    margin-bottom: 40px;
}


@media screen and (max-width: 992px) {
    .accordion__box-block {
        text-align: center;
        width: 100%;
      }
      .copyright {
        justify-content: center;
      }

    .table__column1 {
        position: absolute;
        width: 200px;
    }

    .table__column1 p {
        padding: 16px 0 16px 10px;
        width: 100%;
    }

    .table__column2 p,
    .table__column3 p,
    .table__column4 p {
        padding: 16px 0 16px 180px;
    }



    .table__column2 pre,
    .table__column3 pre,
    .table__column4 pre {
        font-size: 11px;
    }

    .table__column4 pre::before {
        position: absolute;
        content: "";
        left: 35px;
    }

    .table__column3 pre::before {
        position: absolute;
        content: "";
        left: 50px;
    }

    .table__column2 pre::before {
        position: absolute;
        content: "";
        left: 18%;
    }

    .news__thumbnail img {
        width: 100%;
        height: 100%;
    }

    .knowledge-item__thumbnail {
        height: 150px;
    }

}


.page {
    padding-bottom: 0px;
}

.block__text {
    margin-top: 40px;
}

.news__thumbnail {
    padding-bottom: 35px;
    max-width: 800px;
    margin: 0 auto;
}


.news__thumbnail img {
    display: flex;
    margin: 0 auto;
}





.table__mobile {
    width: 100%;
}


.btn.bonus {
    margin: 40px auto 40px;
}

.pagination {
    margin-bottom: 30px;
}

.accordion__box-block {
    margin: 20px 0px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media screen and (max-width: 968px) {
        order: 0;
    }
    
}



.copyright__right a {
    color: #000;
}


.copyright__right a:hover {
    color: #979b9f;
}


article::after {
    display: none;
}

.poster_bonus {
    margin: 0 100px;

    @media screen and (max-width: 890px) {
        margin: 0 auto;
    }
}

.single__table-box-block {
    margin-bottom: 0px;
}

.comand__bord {

    span {
        border: 1px solid #FF7246;
        border-radius: 2px;
        padding: 5px;
        font-size: 16px;
        color: #FF7246;
        font-weight: 500;
    }
}


.knowledge-item__content a:hover {
    color: #3f7fa4;
}

.news-item__title:hover {
    color: #3f7fa4;
}







.list__body-box {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.list__body-left {
    width: 100%;
    max-width: 400px;
    margin-top: 20px;
}

.list__body-right {
    min-width: 600px;
}

.capcha {
    height: 78px;
    margin-top: 30px;
}

.list__body-right .input__block {
    display: flex;
    margin-top: 30px;
}

.list__body-right .input__block .input {

    background: #EEF1F3;
    border: 1px solid #C4C9CE;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 10px 0 10px 14px;
    width: 280px;
}

.first-child {
    margin-bottom: 30px;
}

.first-childs {
    margin-right: 30px;
}

.list__body-right .textarea {
    width: 98.6%;
    background: #EEF1F3;
    border: 1px solid #C4C9CE;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 10px 10px 10px 13px;
    max-width: 800px;
}

.list__body-right .button {
    border: 1px solid #474B4F;
    box-sizing: border-box;
    border-radius: 2px;
    margin: 30px 0px;
    background: #ffffff;
    text-transform: uppercase;
    width: 288px;
    height: 30px;
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
}

.list__body-right .button:hover {
    border: 1px solid #474B4F;
    box-sizing: border-box;
    border-radius: 2px;
    margin: 30px 0px;
    background: #EEF1F3;
}


@media screen and (max-width: 768px) {

    .list__body-right .input__block {
        display: flex;
        flex-direction: column;
    }

    .list__body-right {
        min-width: 200px;
        width: 100%;
    }

    .list__body-right .input__block .input {
        width: 100%;
    }

    .list__body-right .input__block .input:first-child {
        margin-bottom: 30px;
    }

    .list__body-right .textarea {
        width: 100%;
    }

    .list__body-right .button {
        width: 100%;
    }

    .list__body-right .input__block {
        margin-top: 30px;
    }

    .first-child {
        margin-bottom: 0px;
    }

    .commentratingbox label {
        padding: 1px;
    }

    .news-item__thumbnail {
        height: 200px;
    }

    .ad__body img {
        width: 100%;
    }

    .ad__body {
        max-height: 100%;
        height: auto;
    }

}





.knowledge-item__title {

    a:visited {
        color: rgb(0, 0, 0);
    }
}

.prognozy__table {
    margin-left: 19px;
    margin-bottom: 30px;
}

.prognozy__clock {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #6C7074;

}

.prognozy__name {
    font-size: 24px;
    line-height: 37px;
    color: #474B4F;
    font-weight: 400;
}

.prognozy__author {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #6C7074;
}


.prognozy__mnenye {
    margin-top: 60px;

    .prognozy__mnenye-title {
        font-family: Roboto Condensed;
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        line-height: 37px;
        text-transform: uppercase;
        color: #474B4F;
    }
}

.prognozy {
    margin-bottom: 30px;
    // margin-bottom: 60px;
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 37px;
    text-transform: initial;
    color: #474B4F;

    a:hover {
        color: #3f7fa4 !important;
    }
}


.prognose-item {
    height: 174px;
}

.comand__time {
    margin: 10px 0;
    font-size: 14px;
    line-height: 22px;
    text-transform: uppercase;
    color: #474B4F;

    // &:hover {
    //     color: #3f7fa4;
    // }
}

.comand__block {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
    text-transform: initial;

    span {
        color: #6C7074;
    }

    &:hover {
        color: #3f7fa4;
    }
}

.news__thumbnail img {
    width: 100%;
    height: 100%;
}







.tags {
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;


    @media screen and (max-width: 768px) {
        justify-content: center;
        text-align: center;
    }
}

.tags__box {
    max-width: 275px;
    margin-bottom: 20px;
    color: #000000;
    margin: 3px;
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
        max-width: 330px;
        margin-bottom: 20px;
    }

    @media screen and (max-width: 320px) {
        max-width: 275px;

    }
}

.tags__img {

    img {
        height: 150px;
        max-width: 275px;

        @media screen and (max-width: 768px) {
            max-width: 330px;
            height: 100%;
        }

        @media screen and (max-width: 320px) {
            max-width: 275px;
            height: 100%;
        }
    }
}

.tags__title {
    font-size: 16px;
    color: #3c3a3a;
    font-weight: 600;
    line-height: 22px;

    &:hover {
        text-decoration: none;
        color: #6c7277;
    }
}



.termas__box {
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.termas__item1 {

    .termas__link {
        margin-left: 50px;
        display: flex;
        flex-wrap: wrap;
        max-width: 800px;


        @media screen and (max-width: 760px) {
            max-width: 100%;
            margin-left: 0px;
        }
    }
}


.termas__item2 {

    .termas__link {
        margin-left: 16px;
        display: flex;
        flex-wrap: wrap;

        @media screen and (max-width: 760px) {
            max-width: 100%;
            margin-left: 0px;
        }
    }
}

.termas__item3 {

    .termas__link {
        margin-left: 34px;
        display: flex;
        flex-wrap: wrap;

        @media screen and (max-width: 960px) {
            max-width: 100%;
            margin-left: 0px;
        }
    }
}


.termas__item1,
.termas__item2,
.termas__item3 {
    display: flex;


    @media screen and (max-width: 760px) {
        flex-direction: column;
    }

    &>:first-child {
        font-size: 20px;
        text-transform: uppercase;
        margin-bottom: 10px;
        padding-top: 6px;
    }

    a {
        position: relative;
        color: #ffffff;
        font-size: 16px !important;
        text-align: center;
        background-color: #3f7fa4;
        border-radius: 2px;
        padding: 5px 15px;
        margin-bottom: 10px;
        border-bottom: 1px solid #ffffff;
        margin-right: 5px;
        margin-left: 10px;

        @media screen and (max-width: 960px) {
            margin-left: 0px;
            margin-right: 0px;
        }

        &:hover {
            background-color: #58b9ee;
            color: #ffffff;
        }

        &::before {
            position: absolute;
            content: "";
            bottom: 4px;
            left: 5px;
            right: 5px;
            border-radius: 2px;
        }
    }
}



.list__category {
    display: flex;
    flex-wrap: wrap;
    text-transform: uppercase !important;

    .tag-cloud-link {
        margin-right: 10px;
        margin-bottom: 10px;
        position: relative;
        display: block;
        min-width: 100px;
        width: fit-content;
        padding: 5px 20px;
        text-align: center;
        border-radius: 2px;
        background: #3f7fa4;
        font-weight: 700;
        line-height: 22px;
        font-size: 12pt !important;
        text-transform: uppercase !important;
        color: #fff;
        -webkit-transition: all .3s;
        transition: all .3s;
        cursor: pointer;

        &:hover {
            background-color: #58b9ee;
        }
    }
}


.knowledge-item__block {
    display: flex;
    position: relative;
    margin-top: -30px;
    margin-left: 3px;

    &-text {
        background: #ffffff;
        border-radius: 2px;
        padding: 2px 5px;
        color: #3f7fa4;
        text-transform: uppercase;
    }
}

.knowledge-item__block-plus {
    display: flex;
    align-items: center;
    margin-left: 2px;
    background: #ffffff;
    border-radius: 2px;
    padding: 5px;
}

.knowledge-item__block-minus {
    display: flex;
    align-items: center;
    margin-left: 2px;
    background: #ffffff;
    border-radius: 2px;
    padding: 5px;
}



.wp-polls-ans .Buttons {
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;

    min-width: 100px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 20px;
    text-align: center;
    border-radius: 2px;
    background: #3f7fa4;
    font-weight: 700;
    line-height: 22px;
    font-size: 12pt !important;
    text-transform: uppercase !important;
    color: #fff;
    -webkit-transition: all .3s;
    transition: all .3s;
    cursor: pointer;
}

.wp-polls-ul {

    display: flex;
    justify-content: center;
    color: #ffffff;
}

.wp-polls-ul li:nth-child(1) {
    background-color: #00A660;
    padding: 5px 20px;
    border-radius: 5px;
    margin-left: -20px;
}

.wp-polls-ul li:nth-child(2) {
    background-color: #ff3d00;
    padding: 5px 20px;
    border-radius: 5px;
    margin-left: 10px;
}

.pollbar {
    border-radius: 5px;
    background-color: #ffffff;
}

.wp-polls p {
    margin-bottom: 15px;
}

.wp-polls p strong {
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 20px;
    //margin: 10px;
}


.mob {
    margin-right: 10px;
    width: 190px !important;

    @media screen and (max-width: 890px) {
        width: 144px !important;
    }
}