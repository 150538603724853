.pagination {

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;

    li {
      .page-numbers {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        border: 1px solid $textDarkColor;
        margin: 0 3px;
        width: 24px;
        height: 20px;
        text-align: center;
        transition: all .3s;
        @include text-style(normal, 14px, 22px, normal, $textDarkColor);

        &:hover {
          color: $textWhiteColor;
          background: $greyColor;
        }
      }


      .next,
      .dots,
      .prev {
        border: none;
        width: auto;
        height: auto;
        @include text-style(normal, 12px, 12px, normal, $textDarkColor);

        &:hover {
          background: transparent;
          color: $textDarkColor;
        }
      }

      .next,
      .prev {
        padding: 0 15px;
      }

      .prev {
        &::before {
          content: '←';
          opacity: 0;
          padding-right: 5px;
          transition: all .3s;
        }

        &:hover::before {
          opacity: 1;
        }
      }

      .next {
        &::after {
          content: '→';
          opacity: 0;
          padding-left: 5px;
          transition: all .3s;
        }

        &:hover::after {
          opacity: 1;
        }
      }

      .dots {
        cursor: default;
      }

      .current {
        background: $darkColor;
        color: $textWhiteColor;
        cursor: default;

        &:hover {
          background: $darkColor;
        }
      }
    }
  }
}
