.styled-text {
  position: relative;

  .otzyvy-text {
    height: 240px;
    overflow: hidden;
    margin-bottom: 40px;

    &.active {
      height: auto;
    }
  }

  .read-more-btn {
    position: absolute;
    right: 9%;
    bottom: 9px;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;


    &::before {
      content: 'Читать дальше';
      display: inline-block;
    }

    &.active::before {
      content: 'Свернуть';
    }
  }
}


.comment {
  max-width: 945px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  border: 1px solid $textGreyColor;
  list-style: none;
  border-radius: 2px;

  &-form {
    display: flex;
    flex-direction: column;
    max-width: 945px;
    margin-left: auto;
    margin-right: auto;

    .logged-in-as {
      order: 0;
      margin-bottom: 10px;

      &-user {
        color: $mainColor;
        font-weight: bold;
      }

      a {
        color: $accentColor;
      }
    }

    textarea,
    .comment-form-author input {
      width: 100%;
      resize: none;
      border: 1px solid $textGreyColor;
      border-radius: 2px;
      background: $lightColor;
      color: $darkColor;
      border-radius: 2px;
    }

    textarea {
      font-size: 14px;
      line-height: 22px;
      padding: 10px 26px;
      border-radius: 2px;
    }

    &-disadvantages {
      order: 4;
      margin-bottom: 20px;
      display: flex;
      padding-left: 20px;
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 10px;
        height: 100%;
        background: $errorColor;
        border-radius: 2px;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    .author {
      padding: 4px 13px;
      line-height: 22px;
      max-width: 360px;
      margin-bottom: 20px;
    }

    &-comment {
      order: 3;
      margin-bottom: 20px;
      display: flex;
      padding-left: 20px;
      position: relative;

      &::before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 100%;
        background: $doneColor;
        border-radius: 2px;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    &-rating {
      order: 1;
      margin-bottom: 20px;
      position: relative;

      .fl {
        display: flex;
      }

      .commentratingbox {
        input {
          display: none;
        }

        &-text {
          order: 3;
        }
      }
    }

    &-author {
      order: -2;
    }
  }

  &-title-advantages {
    color: $doneColor;
    font-weight: bold;
    margin-bottom: 3px;
  }

  &-title-disadvantages {
    color: $errorColor;
    font-weight: bold;
    margin-bottom: 3px;
    margin-top: 15px;
  }

  &-rating {
    margin: 35px 0;
    @include text-style(normal, 28px, 33px, uppercase, $greyColor);

    strong {
      color: $darkColor;
    }
  }

  &-author {
    overflow: hidden;
    border: 1px solid $textColor;
    border-radius: $radius;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align: top;
    }
  }

  .comment-meta {
    display: flex;
    align-items: center;

    &-info {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }

    &-author {
      @include text-style(bold, 16px, 18px, normal, $darkColor);
      margin-bottom: 3px;
    }

    &-date {
      @include text-style(normal, 12px, 14px, normal, $textColor);
    }
  }

  &-change {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    a {
      @include text-style(normal, 16px, 18px, normal, $mainColor);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-reply-title {
    font-size: 32px;
    line-height: 37px;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 44px;
  }

  &-awaiting-moderation {
    margin-bottom: 20px;
  }

}

.form-submit {
  order: 6;
}

.submit {
  margin: 20px auto 0;
}

.comments-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;

  @media screen and (max-width: 768px) {
    margin-bottom: 50px;
  }

  .page-numbers {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    border: 1px solid $textDarkColor;
    margin: 0 3px;
    width: 24px;
    height: 20px;
    text-align: center;
    transition: all .3s;
    @include text-style(normal, 14px, 22px, normal, $textDarkColor);

    &:hover {
      background: $greyColor;
    }
  }

  .next,
  .dots,
  .prev {
    border: none;
    width: auto;
    height: auto;
    @include text-style(normal, 12px, 12px, normal, $textDarkColor);

    &:hover {
      background: transparent;
      color: $textDarkColor;
    }
  }

  .next,
  .prev {
    padding: 0 15px;
  }

  .prev {
    &::before {
      content: '←';
      opacity: 0;
      padding-right: 5px;
      transition: all .3s;
    }

    &:hover::before {
      opacity: 1;
    }
  }

  .next {
    &::after {
      content: '→';
      opacity: 0;
      padding-left: 5px;
      transition: all .3s;
    }

    &:hover::after {
      opacity: 1;
    }
  }

  .dots {
    cursor: default;
  }

  .current {
    background: $textDarkColor;
    color: $textWhiteColor;
    cursor: default;

    &:hover {
      background: $textDarkColor;
    }
  }
}

.rate-top {
  display: flex;
  align-items: center;

  .fl {
    display: flex;
    align-items: center;
    justify-content: center;
    order: -1;
    margin-right: 20px;
  }

  .cm-placeholder {
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    color: $darkColor;
    background: $lightColor;
    border: 1px solid $textGreyColor;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dwn {
    background: $textGreyColor;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &::after {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 20px 10px 0;
      border-color: $whiteColor transparent transparent transparent;
    }

  }
}

.commentratingbox {
  display: flex;
  flex-direction: column;
  width: 50px;
  position: absolute;
  z-index: 3;
  display: none;

  &.active {
    display: flex;
  }

  label {
    background-color: $whiteColor;
    padding: 2px;
    font-size: 14px;
    border: 1px solid $textGreyColor;
    text-align: center;
    cursor: pointer;

    &:hover {
      background-color: $lightColor;
    }
  }

  input {
    display: none;
  }
}

// plugin Social Login
.mo-openid-app-icons {
  margin: 0 0 20px !important;
  order: 5;

  p {
    @include text-style(normal, 18px, 22px);
    color: $textColor !important;
  }

  a {
    margin-left: -4px;
    display: inline-block;
    cursor: pointer;
  }
}
