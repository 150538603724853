::selection {
  color: $whiteColor;
  background: $mainColor;
}

html {
  @include text-style(normal, $fontSize, 22px, normal, $textColor);
  font-family: $font;
  font-size: ($fontSize / 16px) * 100%;
}

body {
  background-color: $whiteColor;
  position: relative;
}


.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1184px;
  //margin: 0 auto;
  padding: 0 10px;
  height: 100%;
  margin-left: 160px;


  @media screen and (min-width: 1729px) and (max-width: 1918px) {
    margin-left: 90px;
  }

  @media screen and (min-width: 1537px) and (max-width: 1729px) {
    margin-left: 15px;
  }

  @media screen and (min-width: 1440px) and (max-width: 1537px) {
    margin-left: 20px;
  }

  @media screen and (min-width: 1366px) and (max-width: 1440px) {
    margin-left: 10px;
    max-width: 1170px;
  }

  @media screen and (max-width: 1365px) {
    max-width: 100%;
    margin: 0 auto;
    margin-left: 0px;
  }

  &.fluid-mobile {
    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }
}

.hidden {
  display: none !important;
}

.page {
  padding-bottom: 100px;
}

.styled-text {
  @include text-style(normal, $fontSize, 22px, normal, $textColor);
  word-wrap: anywhere;
  letter-spacing: 1px;
  line-height: 1.5;

  h1 {
    @include text-style(normal, 36px, 36px, initial, $textDarkColor);
    padding: 10px 0 30px;
    word-break: break-word;
    letter-spacing: 1px;
    line-height: 1.5;
    font-weight: 600;
    color: #000;
  }

  h2 {
    @include text-style(normal, 22px, 30px, initial, $textDarkColor);
    padding: 10px 0 30px;
    letter-spacing: 1px;
    line-height: 1.5;
    font-weight: 600;
    color: #000;
  }

  h3 {
    @include text-style(normal, 18px, 22px, initial, $textDarkColor);
    padding-bottom: 20px;
    letter-spacing: 1px;
    line-height: 1.5;
    font-weight: 600;
    color: #000;
  }

  h4 {
    @include text-style(normal, 18px, 22px, initial, $textDarkColor);
    padding-bottom: 16px;
    letter-spacing: 1px;
    line-height: 1.5;
    font-weight: 600;
    color: #000;
  }

  a {
    color: $mainColor;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    padding-bottom: 20px;
    letter-spacing: 1px;
    line-height: 1.5;
  }

  blockquote {
    border-left: 4px solid $mainColor;
    margin: 15px 0;
    padding-left: 16px;
    font-weight: bold;

    p:last-child {
      padding-bottom: 0;
    }
  }

  ul {
    list-style: none;

    li {
      position: relative;
      padding-left: 28px;
      margin-bottom: 20px;

      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 10px;
        height: 10px;
        border-radius: $radius;
        transform: translateY(-50%);
        background: #3f7fa4;
      }
    }
  }

  ol {
    counter-reset: item;

    li {
      display: block;
      margin-bottom: 20px;

      &::before {
        content: counter(item) ". ";
        counter-increment: item;
        display: inline-block;
        font-weight: bold;
        width: 24px;
        color: $accentColor;
        padding-right: 4px;
      }
    }
  }

  .wp-block-columns {
    margin-bottom: 20px;

    @media screen and (max-width: 992px) {
      flex-direction: column;
    }
  }

  .wp-block-column:not(:first-child) {
    margin-left: 30px;

    @media screen and (max-width: 992px) {
      margin-left: 0;
    }
  }

  .wp-block-image {
    margin-bottom: 20px;

    img {
      margin-bottom: 0;
    }

    figcaption {
      text-align: center;
    }
  }

  img {
    max-width: 100%;
    height: auto;
    border-radius: $radius;
    margin-bottom: 20px;
  }

  &.center {
    text-align: center;
  }
}

.post-content {

  &>* {
    padding-left: 100px;
    padding-right: 100px;

    @media screen and (max-width: 992px) {
      padding-left: 60px;
      padding-right: 60px;
    }

    @media screen and (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &>h1,
  &>h2,
  &>h3,
  &>h4 {
    padding-left: 20px;
    padding-right: 20px;

    @media screen and (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.for-h {
  position: relative;
  padding: 15px 0 15px 20px;
  margin-bottom: 30px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: $mainColor;
    border-radius: $radius;
  }

  h1 {
    @include text-style(normal, 36px, 36px, initial, $textDarkColor);
    word-break: break-word;
   
  }

  &.mt {
    margin-top: 30px;
  }
}

a {
  text-decoration: none;
}

img {
  user-select: none;
  pointer-events: none;
}