@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 14px;
  line-height: 1.42; }

body {
  margin: 0;
  padding: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

b,
strong {
  font-weight: bold; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0 auto; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/* Additional fixes */
.clearfix:after {
  clear: both;
  display: table;
  content: ' '; }

.kill-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  -webkit-margin-after: 0;
  -webkit-margin-before: 0;
  -webkit-padding-start: 0; }

/* WordPress Styles */
.alignnone {
  margin: 5px 20px 20px 0; }

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto; }

.alignright {
  float: right;
  margin: 5px 0 20px 20px; }

.alignleft {
  float: left;
  margin: 5px 20px 20px 0; }

.aligncenter {
  display: block;
  margin: 5px auto 5px auto; }

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px; }

a img.alignnone {
  margin: 5px 20px 20px 0; }

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0; }

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

@font-face {
  font-family: 'Roboto Condensed';
  src: local("Roboto Condensed Regular"), local("RobotoCondensed-Regular"), url("./fonts/RobotoCondensed-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Roboto Condensed';
  src: local("Roboto Condensed Bold"), local("RobotoCondensed-Bold"), url("./fonts/RobotoCondensed-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

::selection {
  color: #FFFFFF;
  background: #3F7FA4; }

html {
  font-weight: normal;
  line-height: 22px;
  font-size: 1rem;
  text-transform: initial;
  color: #6C7277;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 87.5%; }

body {
  background-color: #FFFFFF;
  position: relative; }

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1184px;
  padding: 0 10px;
  height: 100%;
  margin-left: 160px; }
  @media screen and (min-width: 1729px) and (max-width: 1918px) {
    .container {
      margin-left: 90px; } }
  @media screen and (min-width: 1537px) and (max-width: 1729px) {
    .container {
      margin-left: 15px; } }
  @media screen and (min-width: 1440px) and (max-width: 1537px) {
    .container {
      margin-left: 20px; } }
  @media screen and (min-width: 1366px) and (max-width: 1440px) {
    .container {
      margin-left: 10px;
      max-width: 1170px; } }
  @media screen and (max-width: 1365px) {
    .container {
      max-width: 100%;
      margin: 0 auto;
      margin-left: 0px; } }
  @media screen and (max-width: 768px) {
    .container.fluid-mobile {
      padding: 0; } }

.hidden {
  display: none !important; }

.page {
  padding-bottom: 100px; }

.styled-text {
  font-weight: normal;
  line-height: 22px;
  font-size: 1rem;
  text-transform: initial;
  color: #6C7277;
  word-wrap: anywhere;
  letter-spacing: 1px;
  line-height: 1.5; }
  .styled-text h1 {
    font-weight: normal;
    line-height: 36px;
    font-size: 2.57143rem;
    text-transform: initial;
    color: #000000;
    padding: 10px 0 30px;
    word-break: break-word;
    letter-spacing: 1px;
    line-height: 1.5;
    font-weight: 600;
    color: #000; }
  .styled-text h2 {
    font-weight: normal;
    line-height: 30px;
    font-size: 1.57143rem;
    text-transform: initial;
    color: #000000;
    padding: 10px 0 30px;
    letter-spacing: 1px;
    line-height: 1.5;
    font-weight: 600;
    color: #000; }
  .styled-text h3 {
    font-weight: normal;
    line-height: 22px;
    font-size: 1.28571rem;
    text-transform: initial;
    color: #000000;
    padding-bottom: 20px;
    letter-spacing: 1px;
    line-height: 1.5;
    font-weight: 600;
    color: #000; }
  .styled-text h4 {
    font-weight: normal;
    line-height: 22px;
    font-size: 1.28571rem;
    text-transform: initial;
    color: #000000;
    padding-bottom: 16px;
    letter-spacing: 1px;
    line-height: 1.5;
    font-weight: 600;
    color: #000; }
  .styled-text a {
    color: #3F7FA4;
    text-decoration: none; }
    .styled-text a:hover {
      text-decoration: underline; }
  .styled-text p {
    padding-bottom: 20px;
    letter-spacing: 1px;
    line-height: 1.5; }
  .styled-text blockquote {
    border-left: 4px solid #3F7FA4;
    margin: 15px 0;
    padding-left: 16px;
    font-weight: bold; }
    .styled-text blockquote p:last-child {
      padding-bottom: 0; }
  .styled-text ul {
    list-style: none; }
    .styled-text ul li {
      position: relative;
      padding-left: 28px;
      margin-bottom: 20px; }
      .styled-text ul li::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 10px;
        height: 10px;
        border-radius: 2px;
        transform: translateY(-50%);
        background: #3f7fa4; }
  .styled-text ol {
    counter-reset: item; }
    .styled-text ol li {
      display: block;
      margin-bottom: 20px; }
      .styled-text ol li::before {
        content: counter(item) ". ";
        counter-increment: item;
        display: inline-block;
        font-weight: bold;
        width: 24px;
        color: #FF3D00;
        padding-right: 4px; }
  .styled-text .wp-block-columns {
    margin-bottom: 20px; }
    @media screen and (max-width: 992px) {
      .styled-text .wp-block-columns {
        flex-direction: column; } }
  .styled-text .wp-block-column:not(:first-child) {
    margin-left: 30px; }
    @media screen and (max-width: 992px) {
      .styled-text .wp-block-column:not(:first-child) {
        margin-left: 0; } }
  .styled-text .wp-block-image {
    margin-bottom: 20px; }
    .styled-text .wp-block-image img {
      margin-bottom: 0; }
    .styled-text .wp-block-image figcaption {
      text-align: center; }
  .styled-text img {
    max-width: 100%;
    height: auto;
    border-radius: 2px;
    margin-bottom: 20px; }
  .styled-text.center {
    text-align: center; }

.post-content > * {
  padding-left: 100px;
  padding-right: 100px; }
  @media screen and (max-width: 992px) {
    .post-content > * {
      padding-left: 60px;
      padding-right: 60px; } }
  @media screen and (max-width: 768px) {
    .post-content > * {
      padding-left: 0;
      padding-right: 0; } }

.post-content > h1,
.post-content > h2,
.post-content > h3,
.post-content > h4 {
  padding-left: 20px;
  padding-right: 20px; }
  @media screen and (max-width: 768px) {
    .post-content > h1,
    .post-content > h2,
    .post-content > h3,
    .post-content > h4 {
      padding-left: 0;
      padding-right: 0; } }

.for-h {
  position: relative;
  padding: 15px 0 15px 20px;
  margin-bottom: 30px; }
  .for-h::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: #3F7FA4;
    border-radius: 2px; }
  .for-h h1 {
    font-weight: normal;
    line-height: 36px;
    font-size: 2.57143rem;
    text-transform: initial;
    color: #000000;
    word-break: break-word; }
  .for-h.mt {
    margin-top: 30px; }

a {
  text-decoration: none; }

img {
  user-select: none;
  pointer-events: none; }

.burger {
  flex-direction: column;
  justify-content: center;
  width: 25px;
  cursor: pointer;
  height: auto; }
  .burger__line {
    position: relative;
    display: block;
    height: 5px;
    width: 100%;
    border-radius: 2px;
    overflow: hidden;
    background-color: #FFFFFF;
    transition: all .3s; }
    .burger__line_top {
      top: 0; }
    .burger__line_middle {
      margin: 5px 0; }
    .burger__line_bottom {
      bottom: 0;
      margin-bottom: 0; }
  .burger_active .burger__line {
    background-color: #FFFFFF; }
    .burger_active .burger__line_top {
      transform: rotate(45deg);
      top: 10px; }
    .burger_active .burger__line_middle {
      opacity: 0; }
    .burger_active .burger__line_bottom {
      transform: rotate(-45deg);
      bottom: 10px; }

.menu ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .menu ul li {
    padding: 5px 0; }
    @media screen and (max-width: 992px) {
      .menu ul li {
        padding: 5px 0; } }
    .menu ul li a,
    .menu ul li span {
      font-weight: bold;
      line-height: 22px;
      font-size: 1rem;
      text-transform: initial;
      color: #FFFFFF;
      padding: 2px 0px;
      border-radius: 2px;
      transition: all .3s;
      width: 100%;
      text-transform: uppercase; }
    .menu ul li a:hover {
      color: #7BC4EF; }
    .menu ul li span {
      background-color: #7BC4EF;
      cursor: context-menu; }

@media screen and (max-width: 992px) {
  .menu.menu_header {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #3F7FA4;
    width: 100%;
    padding: 20px 0;
    border-bottom: 4px solid #2A5F7D; } }

.menu.menu_header ul {
  justify-content: flex-end; }
  @media screen and (max-width: 992px) {
    .menu.menu_header ul {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 0 22px; } }
  .menu.menu_header ul li {
    padding-left: 13px; }
    @media screen and (max-width: 992px) {
      .menu.menu_header ul li {
        padding-left: 0; } }
    .menu.menu_header ul li:first-child {
      padding-left: 0; }

.menu.menu_footer ul {
  align-items: start;
  flex-direction: column; }
  @media screen and (max-width: 768px) {
    .menu.menu_footer ul {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center; } }
  .menu.menu_footer ul li {
    margin-bottom: -13px; }
    @media screen and (max-width: 968px) {
      .menu.menu_footer ul li {
        display: flex;
        text-align: center;
        width: 100%; } }
    .menu.menu_footer ul li:last-child {
      padding-right: 0; }
    .menu.menu_footer ul li a,
    .menu.menu_footer ul li span {
      font-weight: normal;
      text-transform: capitalize; }

.topmenu > li {
  float: left;
  position: relative; }

.topmenu > li > a {
  padding: 0px 25px;
  font-size: 12px;
  text-transform: uppercase;
  color: #FEFDFD;
  letter-spacing: 1px; }

.topmenu > li > a.active,
.submenu a:hover {
  background: linear-gradient(90deg, #f7ff00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.submenu {
  position: absolute;
  z-index: 5;
  left: 13px;
  width: 200px;
  opacity: 0;
  display: none !important;
  padding-top: 10px;
  border: 2px solid #f7ff00;
  background-color: #3f7fa4; }
  @media screen and (max-width: 768px) {
    .submenu {
      border: 2px solid #f7ff00;
      left: 0px !important;
      width: 100%;
      overflow: hidden; } }

.submenu li {
  position: relative;
  list-style-type: none;
  padding: 5px 10px !important;
  font-size: 18px !important; }
  @media screen and (max-width: 768px) {
    .submenu li {
      margin-left: 0px;
      padding: 5px 0px !important;
      height: 100% !important; } }

.submenu li a {
  color: #ffffff;
  padding: 8px;
  font-size: 14px; }

.submenu .submenu {
  position: absolute;
  left: 100%;
  top: 0px;
  transition: .1s;
  padding-left: 5px;
  padding-top: 0px; }

.menu li:hover > .submenu {
  opacity: 1;
  display: block !important; }
  @media screen and (max-width: 768px) {
    .menu li:hover > .submenu {
      position: relative !important; } }

.topmenu li a {
  position: relative; }

.topmenu li a:after {
  color: white;
  content: ' ▾'; }

.topmenu li a:hover:after {
  color: white;
  content: ' ▾'; }

.topmenu li a:only-child:after {
  content: ''; }

.custom_breadcrumbs {
  text-align: left;
  padding: 15px 0; }
  .custom_breadcrumbs a,
  .custom_breadcrumbs span {
    text-decoration: none;
    font-weight: bold;
    line-height: 22px;
    font-size: 1rem;
    text-transform: initial;
    color: #6C7277; }
  .custom_breadcrumbs a:hover {
    text-decoration: underline; }
  .custom_breadcrumbs .kb_title {
    color: #000000; }

.poster {
  width: 100%;
  height: 240px;
  margin-bottom: 30px;
  background: #C4C9CE url("./images/default-poster.jpg") center/cover no-repeat;
  border-radius: 2px; }
  .poster__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    padding: 20px; }
  .poster__logo {
    margin-bottom: 10px; }
    .poster__logo img {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 150px;
      vertical-align: top; }
  .poster__rating {
    text-align: center;
    font-weight: normal;
    line-height: 33px;
    font-size: 2rem;
    text-transform: initial;
    color: #C4C9CE; }
    .poster__rating strong {
      color: #FFFFFF; }
  .poster__name {
    font-weight: bold;
    line-height: 36px;
    font-size: 2.57143rem;
    text-transform: initial;
    color: #FFFFFF; }
    @media screen and (max-width: 768px) {
      .poster__name {
        font-size: 1.57143rem; } }
  .poster__num {
    font-weight: bold;
    line-height: 84px;
    font-size: 6rem;
    text-transform: initial;
    color: #FFA800; }
    @media screen and (max-width: 768px) {
      .poster__num {
        font-size: 3rem;
        line-height: 36px; } }
  .poster_bonus {
    width: auto;
    margin: 0 100px 30px;
    max-width: 945px;
    background-image: url("./images/bonus-poster.jpg"); }
    @media screen and (max-width: 992px) {
      .poster_bonus {
        margin: 0 60px 30px; } }
    @media screen and (max-width: 768px) {
      .poster_bonus {
        margin: 0 0 30px; } }
    @media screen and (max-width: 544px) {
      .poster_bonus {
        background-image: url("./images/bonus-poster_mob.jpg"); } }
    .poster_bonus .poster__body {
      align-items: flex-start;
      padding: 10px 20px; }
      @media screen and (max-width: 768px) {
        .poster_bonus .poster__body {
          padding: 20px; } }
    .poster_bonus .poster__logo img {
      width: auto;
      max-height: 85px; }

.btn {
  position: relative;
  display: block;
  min-width: 128px;
  width: fit-content;
  padding: 5px 25px;
  text-align: center;
  border-radius: 2px;
  border: 1px solid #FF3D00;
  background: #FF3D00;
  font-weight: bold;
  line-height: 22px;
  font-size: 1.28571rem;
  text-transform: initial;
  color: #FFFFFF;
  transition: all .3s;
  cursor: pointer; }
  .btn:hover, .btn:active {
    border-color: #FF7246;
    background: #FF7246; }
  .btn_o {
    border-color: #474B4F;
    background: transparent;
    color: #474B4F; }
    .btn_o:hover, .btn_o:active {
      border-color: #474B4F;
      background: #C4C9CE; }
  .btn.sm {
    min-width: 105px;
    font-size: 1rem; }
  .btn.bonus {
    margin: 40px auto 0;
    min-width: 280px; }
    @media screen and (max-width: 544px) {
      .btn.bonus {
        min-width: 100%; } }

.button-otz {
  color: #ffff !important;
  text-decoration: none;
  background: #3f7fa4;
  border: #3f7fa4;
  padding: 5px 70px;
  text-align: center;
  min-width: 128px;
  margin: 60px auto; }
  .button-otz:hover {
    text-decoration: none !important;
    background: #4996c2 !important; }

.star-button-top, .aside__item .aside__item-button,
.btn {
  border-radius: 10px; }

.table__button_ref {
  border-top: 1px solid #c4c9ce;
  min-width: 113px;
  padding: 1px 12px;
  line-height: 31px;
  font-size: 1rem;
  margin-top: 0px; }

@media screen and (max-width: 968px) {
  .table__button_ref {
    border-top: 1px solid #c4c9ce;
    min-width: 80px;
    border-radius: 0; }
  .star-button-top, .aside__item .aside__item-button,
  .btn {
    border-radius: 0px; } }

.post-item {
  display: flex;
  flex-direction: column;
  border: 1px solid #C4C9CE;
  border-radius: 2px;
  background-color: #FFFFFF;
  overflow: hidden; }
  .post-item__top {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px; }
  .post-item__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 20px 10px; }
  .post-item__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px; }
    .post-item__title-label {
      font-weight: normal;
      line-height: 22px;
      font-size: 1rem;
      text-transform: initial;
      color: #000000; }
    .post-item__title-name {
      margin-bottom: 10px;
      font-weight: bold;
      line-height: 22px;
      font-size: 1.57143rem;
      text-transform: initial;
      color: #000000; }
    .post-item__title-rating {
      font-weight: normal;
      line-height: 33px;
      font-size: 2rem;
      text-transform: initial;
      color: #C4C9CE; }
      .post-item__title-rating strong {
        color: #000000; }
  .post-item__text {
    font-weight: normal;
    line-height: 22px;
    font-size: 1rem;
    text-transform: initial;
    color: #6C7277; }
  .post-item__img {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 5px;
    max-height: 80px; }
    .post-item__img img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-height: 80px; }
  .post-item__bottom {
    width: 100%;
    padding: 0 20px 20px; }
  .post-item__button {
    width: 100%; }

.bonus-item {
  display: flex;
  flex-direction: column;
  border: 1px solid #C4C9CE;
  border-radius: 2px;
  background-color: #FFFFFF;
  overflow: hidden; }
  .bonus-item__top {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px; }
  .bonus-item__info {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 20px;
    height: 260px;
    background: #C4C9CE url("./images/bonus-poster_mob.jpg") center/cover no-repeat;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between; }
    .bonus-item__info img {
      width: auto;
      max-height: 60px; }
    .bonus-item__info span {
      user-select: none; }
    .bonus-item__info-name {
      font-weight: bold;
      line-height: 36px;
      font-size: 1.57143rem;
      text-transform: initial;
      color: #FFFFFF; }
    .bonus-item__info-num {
      font-weight: bold;
      line-height: 36px;
      font-size: 3rem;
      text-transform: initial;
      color: #FFA800;
      display: block; }
  .bonus-item__content {
    flex-grow: 1; }
  .bonus-item__text {
    padding: 0 20px 20px;
    font-weight: bold;
    line-height: 22px;
    font-size: 1rem;
    text-transform: initial;
    color: #474B4F; }
  .bonus-item__bottom {
    width: 100%;
    padding: 0 20px 20px; }
  .bonus-item__button {
    width: 100%; }

.news-item {
  display: flex;
  flex-direction: column;
  border: 1px solid #C4C9CE;
  border-radius: 2px;
  background-color: #FFFFFF;
  overflow: hidden; }
  .news-item__thumbnail {
    display: flex;
    flex-direction: column;
    height: 175px; }
  .news-item__img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .news-item__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    padding: 15px 20px 15px; }
  .news-item__tags {
    border: 1px solid #474B4F;
    border-radius: 2px;
    align-items: center;
    margin-bottom: 10px;
    padding: 4px 8px;
    font-weight: normal;
    line-height: 12px;
    font-size: 0.85714rem;
    text-transform: initial;
    color: #000000; }
  .news-item__date {
    padding-bottom: 10px;
    font-weight: normal;
    line-height: 22px;
    font-size: 1rem;
    text-transform: initial;
    color: #000000; }
  .news-item__title {
    font-weight: bold;
    line-height: 22px;
    font-size: 1.57143rem;
    text-transform: initial;
    color: #000000; }
  .news-item__bottom {
    width: 100%;
    padding: 0 20px 20px; }
  .news-item__button {
    width: 100%; }

.knowledge-item {
  display: flex;
  flex-direction: column;
  border: 1px solid #C4C9CE;
  border-radius: 2px;
  background-color: #FFFFFF;
  overflow: hidden; }
  .knowledge-item__thumbnail {
    display: flex;
    flex-direction: column;
    height: 115px; }
  .knowledge-item__img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .knowledge-item__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex-grow: 1;
    padding: 15px 20px 15px; }
  .knowledge-item__tags {
    border: 1px solid #474B4F;
    border-radius: 2px;
    align-items: center;
    margin-bottom: 10px;
    padding: 4px 8px;
    font-weight: normal;
    line-height: 12px;
    font-size: 0.85714rem;
    text-transform: initial;
    color: #000000; }
  .knowledge-item__title {
    font-weight: bold;
    line-height: 22px;
    font-size: 1.57143rem;
    text-transform: initial;
    color: #000000; }
  .knowledge-item__bottom {
    width: 100%;
    padding: 0 20px 20px; }
  .knowledge-item__button {
    width: 100%; }

.knowledge-imgages {
  max-height: 135px;
  width: 100%;
  padding-bottom: 15px; }
  @media screen and (max-width: 992px) {
    .knowledge-imgages {
      max-height: 180px; } }

.post-contentsos .news-item__title {
  font-size: 14px;
  display: flex;
  align-items: center; }

@media screen and (max-width: 992px) {
  .knowledge-item__thumbnail {
    display: none; } }

.last {
  padding-top: 60px; }
  .last__header {
    margin-bottom: 30px; }
    .last__header h2 {
      font-weight: normal;
      line-height: 37px;
      font-size: 2.28571rem;
      text-transform: initial;
      color: #000000; }
      @media screen and (max-width: 768px) {
        .last__header h2 {
          font-size: 1.57143rem;
          line-height: 30px; } }
  .last__body {
    padding-left: 100px;
    padding-right: 100px; }
    @media screen and (max-width: 992px) {
      .last__body {
        padding-left: 60px;
        padding-right: 60px; } }
    @media screen and (max-width: 768px) {
      .last__body {
        padding-left: 0;
        padding-right: 0; } }
  .last__list {
    list-style: none; }
  .last__item {
    position: relative;
    padding-left: 35px;
    margin-bottom: 30px; }
    .last__item::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 20px;
      height: 20px;
      border-radius: 2px;
      transform: translateY(-50%);
      background: #3F7FA4; }
  .last__link {
    text-decoration: none;
    font-weight: normal;
    line-height: 28px;
    font-size: 1.71429rem;
    text-transform: initial;
    color: #000000; }
    .last__link:hover {
      color: #3F7FA4; }
    @media screen and (max-width: 768px) {
      .last__link {
        font-size: 1.14286rem;
        line-height: 18px; } }

.pagination ul {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none; }
  .pagination ul li .page-numbers {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    border: 1px solid #000000;
    margin: 0 3px;
    width: 24px;
    height: 20px;
    text-align: center;
    transition: all .3s;
    font-weight: normal;
    line-height: 22px;
    font-size: 1rem;
    text-transform: initial;
    color: #000000; }
    .pagination ul li .page-numbers:hover {
      color: #FFFFFF;
      background: #C4C9CE; }
  .pagination ul li .next,
  .pagination ul li .dots,
  .pagination ul li .prev {
    border: none;
    width: auto;
    height: auto;
    font-weight: normal;
    line-height: 12px;
    font-size: 0.85714rem;
    text-transform: initial;
    color: #000000; }
    .pagination ul li .next:hover,
    .pagination ul li .dots:hover,
    .pagination ul li .prev:hover {
      background: transparent;
      color: #000000; }
  .pagination ul li .next,
  .pagination ul li .prev {
    padding: 0 15px; }
  .pagination ul li .prev::before {
    content: '←';
    opacity: 0;
    padding-right: 5px;
    transition: all .3s; }
  .pagination ul li .prev:hover::before {
    opacity: 1; }
  .pagination ul li .next::after {
    content: '→';
    opacity: 0;
    padding-left: 5px;
    transition: all .3s; }
  .pagination ul li .next:hover::after {
    opacity: 1; }
  .pagination ul li .dots {
    cursor: default; }
  .pagination ul li .current {
    background: #474B4F;
    color: #FFFFFF;
    cursor: default; }
    .pagination ul li .current:hover {
      background: #474B4F; }

.article__date {
  padding-left: 22px;
  margin-bottom: 30px;
  font-weight: normal;
  line-height: 37px;
  font-size: 2.28571rem;
  text-transform: initial;
  color: #000000; }
  @media screen and (max-width: 768px) {
    .article__date {
      font-size: 1.71429rem;
      line-height: 28px; } }

.article__thumbnail {
  position: relative;
  margin: 0 100px 30px;
  height: 100%;
  padding-top: 39.3617%;
  border-radius: 2px;
  overflow: hidden; }
  @media screen and (max-width: 992px) {
    .article__thumbnail {
      margin: 0 60px 30px; } }
  @media screen and (max-width: 768px) {
    .article__thumbnail {
      margin: 0 0 30px; } }

.article__img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 800px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0; }

.article__tags {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-left: 100px;
  padding-right: 100px;
  margin-bottom: 10px;
  list-style: none; }
  @media screen and (max-width: 992px) {
    .article__tags {
      padding-left: 60px;
      padding-right: 60px; } }
  @media screen and (max-width: 768px) {
    .article__tags {
      padding-left: 0;
      padding-right: 0; } }
  .article__tags li {
    border: 1px solid #474B4F;
    border-radius: 2px;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 4px 8px;
    font-weight: normal;
    line-height: 22px;
    font-size: 1rem;
    text-transform: initial;
    color: #000000; }

.table__mobile {
  display: none;
  margin-bottom: 50px;
  width: 115%; }

@media screen and (max-width: 992px) {
  .table__mobile {
    display: block; } }

.single__table-box-block {
  margin-bottom: 50px; }

@media screen and (max-width: 992px) {
  .single__table-box-block {
    display: none; } }

.single__acardion {
  margin: 50px 0px; }

.single__acardion-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.single__acardion-plus {
  width: 49%;
  border: 1px solid #C4C9CE; }
  @media screen and (max-width: 992px) {
    .single__acardion-plus {
      width: 100%; } }

.acardion-plus__header p {
  background-color: #000000;
  padding: 12px 0px 12px 20px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #FFFFFF; }

.plus__atem {
  position: relative;
  padding: 16px 0px 16px 58px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #6B7286;
  border-top: 1px solid #C4C9CE; }

.plus__atem::before {
  position: absolute;
  content: "";
  top: 35%;
  left: 20px;
  width: 20px;
  height: 20px;
  background-image: url(./images/plus.svg);
  background-repeat: no-repeat; }

.single__acardion-minus {
  width: 49%;
  border: 1px solid #C4C9CE; }
  @media screen and (max-width: 992px) {
    .single__acardion-minus {
      width: 100%;
      margin-top: 20px; } }

.acardion-minus__header p {
  background-color: #000000;
  padding: 12px 0px 12px 20px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #FFFFFF; }

.minus__atem {
  position: relative;
  padding: 16px 0px 16px 58px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #6B7286;
  border-top: 1px solid #C4C9CE; }

.minus__atem::before {
  position: absolute;
  content: "";
  top: 45%;
  left: 20px;
  width: 20px;
  height: 20px;
  background-image: url(./images/min.svg);
  background-repeat: no-repeat; }

.single__table-box {
  border: 1px solid #C4C9CE;
  display: flex;
  justify-content: space-between; }

.table__column1,
.table__column2,
.table__column3,
.table__column4 {
  width: 25%; }

@media screen and (max-width: 992px) {
  .table__column1,
  .table__column2,
  .table__column3,
  .table__column4 {
    width: 100%; } }

.table__column1 p {
  padding: 16px 0px 16px 20px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: #000000;
  border-top: 1px solid #C4C9CE; }

@media screen and (max-width: 992px) {
  .table__column1 p {
    padding: 16px 0px 16px 10px;
    width: 286px; } }

@media screen and (max-width: 992px) {
  .table__column1 {
    position: absolute; } }

.table__column2 p,
.table__column3 p,
.table__column4 p {
  padding: 16px 0px 16px 20px;
  position: relative;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #00A660;
  border-top: 1px solid #C4C9CE;
  border-left: 1px solid #C4C9CE; }

@media screen and (max-width: 992px) {
  .table__column2 p,
  .table__column3 p,
  .table__column4 p {
    padding: 16px 0px 16px 200px; } }

.table__column2 pre,
.table__column3 pre,
.table__column4 pre {
  text-align: center;
  padding: 16px 0px 15px 35px;
  position: relative;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: #3F7FA4;
  border-left: 1px solid #C4C9CE; }

@media screen and (max-width: 992px) {
  .table__column2 pre,
  .table__column3 pre,
  .table__column4 pre {
    padding: 16px 0px 15px 10px; } }

.table__column2 pre::before {
  position: absolute;
  content: "";
  left: 60px;
  width: 30px;
  height: 30px;
  background-image: url(./images/tab1.svg);
  background-repeat: no-repeat; }

@media screen and (max-width: 992px) {
  .table__column2 pre::before {
    position: absolute;
    content: "";
    left: 60px;
    width: 30px;
    height: 30px;
    background-image: url(./images/tab1.svg);
    background-repeat: no-repeat; } }

.table__column3 pre::before {
  position: absolute;
  content: "";
  left: 70px;
  width: 30px;
  height: 30px;
  background-image: url(./images/tab2.svg);
  background-repeat: no-repeat; }

@media screen and (max-width: 992px) {
  .table__column3 pre::before {
    position: absolute;
    content: "";
    left: 65px;
    width: 30px;
    height: 30px;
    background-image: url(./images/tab2.svg);
    background-repeat: no-repeat; } }

.table__column4 pre::before {
  position: absolute;
  content: "";
  left: 45px;
  width: 30px;
  height: 30px;
  background-image: url(./images/tab3.svg);
  background-repeat: no-repeat; }

@media screen and (max-width: 992px) {
  .table__column4 pre::before {
    position: absolute;
    content: "";
    left: 57px;
    width: 30px;
    height: 30px;
    background-image: url(./images/tab3.svg);
    background-repeat: no-repeat; } }

.table__slider {
  width: 100%; }

.post-slider__arrow {
  position: absolute;
  top: 0;
  background: none;
  border: none;
  font-size: 30px; }

.post-slider__prev {
  z-index: 2;
  left: 0;
  top: 16px;
  margin-left: 20px; }

.post-slider__next {
  right: 0;
  top: 16px;
  margin-right: 20px; }

.post-item__text {
  display: none; }

.copyright {
  display: flex;
  padding: 6px 2px 2px 5px;
  margin-bottom: 8px; }

.accordion {
  background: white;
  color: #000;
  cursor: pointer;
  padding: 6px 2px 0px 5px;
  width: 182px;
  text-align: center;
  outline: none;
  font-size: 14px;
  transition: 0.5s; }

.accordion:after {
  content: '\25B2';
  color: #000;
  font-size: 10px;
  font-weight: bold;
  margin-top: 2px;
  margin-left: 5px; }

.active:after {
  content: "\25BC"; }

.accordion__map {
  margin-top: 5px; }

.panel {
  margin-top: -217px;
  position: relative;
  padding: 0 14px;
  color: #fff;
  background-color: #fff;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height .3s ease-out;
  transition: max-height .3s ease-out;
  text-align: center; }

.panel div {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  color: #404040; }

.panel div a {
  color: #404040;
  text-decoration: none; }

.panel div a:hover {
  color: #3f7fa4;
  font-size: 13px; }

.styled-text {
  font-size: 1.1rem; }

.the_champ_sharing_ul {
  list-style-type: none; }

.form-submit {
  margin-bottom: 40px; }

@media screen and (max-width: 992px) {
  .accordion__box-block {
    text-align: center;
    width: 100%; }
  .copyright {
    justify-content: center; }
  .table__column1 {
    position: absolute;
    width: 200px; }
  .table__column1 p {
    padding: 16px 0 16px 10px;
    width: 100%; }
  .table__column2 p,
  .table__column3 p,
  .table__column4 p {
    padding: 16px 0 16px 180px; }
  .table__column2 pre,
  .table__column3 pre,
  .table__column4 pre {
    font-size: 11px; }
  .table__column4 pre::before {
    position: absolute;
    content: "";
    left: 35px; }
  .table__column3 pre::before {
    position: absolute;
    content: "";
    left: 50px; }
  .table__column2 pre::before {
    position: absolute;
    content: "";
    left: 18%; }
  .news__thumbnail img {
    width: 100%;
    height: 100%; }
  .knowledge-item__thumbnail {
    height: 150px; } }

.page {
  padding-bottom: 0px; }

.block__text {
  margin-top: 40px; }

.news__thumbnail {
  padding-bottom: 35px;
  max-width: 800px;
  margin: 0 auto; }

.news__thumbnail img {
  display: flex;
  margin: 0 auto; }

.table__mobile {
  width: 100%; }

.btn.bonus {
  margin: 40px auto 40px; }

.pagination {
  margin-bottom: 30px; }

.accordion__box-block {
  margin: 20px 0px;
  display: flex;
  justify-content: space-between;
  flex-direction: column; }
  @media screen and (max-width: 968px) {
    .accordion__box-block {
      order: 0; } }

.copyright__right a {
  color: #000; }

.copyright__right a:hover {
  color: #979b9f; }

article::after {
  display: none; }

.poster_bonus {
  margin: 0 100px; }
  @media screen and (max-width: 890px) {
    .poster_bonus {
      margin: 0 auto; } }

.single__table-box-block {
  margin-bottom: 0px; }

.comand__bord span {
  border: 1px solid #FF7246;
  border-radius: 2px;
  padding: 5px;
  font-size: 16px;
  color: #FF7246;
  font-weight: 500; }

.knowledge-item__content a:hover {
  color: #3f7fa4; }

.news-item__title:hover {
  color: #3f7fa4; }

.list__body-box {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.list__body-left {
  width: 100%;
  max-width: 400px;
  margin-top: 20px; }

.list__body-right {
  min-width: 600px; }

.capcha {
  height: 78px;
  margin-top: 30px; }

.list__body-right .input__block {
  display: flex;
  margin-top: 30px; }

.list__body-right .input__block .input {
  background: #EEF1F3;
  border: 1px solid #C4C9CE;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 10px 0 10px 14px;
  width: 280px; }

.first-child {
  margin-bottom: 30px; }

.first-childs {
  margin-right: 30px; }

.list__body-right .textarea {
  width: 98.6%;
  background: #EEF1F3;
  border: 1px solid #C4C9CE;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 10px 10px 10px 13px;
  max-width: 800px; }

.list__body-right .button {
  border: 1px solid #474B4F;
  box-sizing: border-box;
  border-radius: 2px;
  margin: 30px 0px;
  background: #ffffff;
  text-transform: uppercase;
  width: 288px;
  height: 30px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  text-align: center; }

.list__body-right .button:hover {
  border: 1px solid #474B4F;
  box-sizing: border-box;
  border-radius: 2px;
  margin: 30px 0px;
  background: #EEF1F3; }

@media screen and (max-width: 768px) {
  .list__body-right .input__block {
    display: flex;
    flex-direction: column; }
  .list__body-right {
    min-width: 200px;
    width: 100%; }
  .list__body-right .input__block .input {
    width: 100%; }
  .list__body-right .input__block .input:first-child {
    margin-bottom: 30px; }
  .list__body-right .textarea {
    width: 100%; }
  .list__body-right .button {
    width: 100%; }
  .list__body-right .input__block {
    margin-top: 30px; }
  .first-child {
    margin-bottom: 0px; }
  .commentratingbox label {
    padding: 1px; }
  .news-item__thumbnail {
    height: 200px; }
  .ad__body img {
    width: 100%; }
  .ad__body {
    max-height: 100%;
    height: auto; } }

.knowledge-item__title a:visited {
  color: black; }

.prognozy__table {
  margin-left: 19px;
  margin-bottom: 30px; }

.prognozy__clock {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #6C7074; }

.prognozy__name {
  font-size: 24px;
  line-height: 37px;
  color: #474B4F;
  font-weight: 400; }

.prognozy__author {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #6C7074; }

.prognozy__mnenye {
  margin-top: 60px; }
  .prognozy__mnenye .prognozy__mnenye-title {
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 37px;
    text-transform: uppercase;
    color: #474B4F; }

.prognozy {
  margin-bottom: 30px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 37px;
  text-transform: initial;
  color: #474B4F; }
  .prognozy a:hover {
    color: #3f7fa4 !important; }

.prognose-item {
  height: 174px; }

.comand__time {
  margin: 10px 0;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: #474B4F; }

.comand__block {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  text-transform: initial; }
  .comand__block span {
    color: #6C7074; }
  .comand__block:hover {
    color: #3f7fa4; }

.news__thumbnail img {
  width: 100%;
  height: 100%; }

.tags {
  justify-content: flex-start;
  display: flex;
  flex-wrap: wrap; }
  @media screen and (max-width: 768px) {
    .tags {
      justify-content: center;
      text-align: center; } }

.tags__box {
  max-width: 275px;
  margin-bottom: 20px;
  color: #000000;
  margin: 3px;
  margin-bottom: 20px; }
  @media screen and (max-width: 768px) {
    .tags__box {
      max-width: 330px;
      margin-bottom: 20px; } }
  @media screen and (max-width: 320px) {
    .tags__box {
      max-width: 275px; } }

.tags__img img {
  height: 150px;
  max-width: 275px; }
  @media screen and (max-width: 768px) {
    .tags__img img {
      max-width: 330px;
      height: 100%; } }
  @media screen and (max-width: 320px) {
    .tags__img img {
      max-width: 275px;
      height: 100%; } }

.tags__title {
  font-size: 16px;
  color: #3c3a3a;
  font-weight: 600;
  line-height: 22px; }
  .tags__title:hover {
    text-decoration: none;
    color: #6c7277; }

.termas__box {
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column; }

.termas__item1 .termas__link {
  margin-left: 50px;
  display: flex;
  flex-wrap: wrap;
  max-width: 800px; }
  @media screen and (max-width: 760px) {
    .termas__item1 .termas__link {
      max-width: 100%;
      margin-left: 0px; } }

.termas__item2 .termas__link {
  margin-left: 16px;
  display: flex;
  flex-wrap: wrap; }
  @media screen and (max-width: 760px) {
    .termas__item2 .termas__link {
      max-width: 100%;
      margin-left: 0px; } }

.termas__item3 .termas__link {
  margin-left: 34px;
  display: flex;
  flex-wrap: wrap; }
  @media screen and (max-width: 960px) {
    .termas__item3 .termas__link {
      max-width: 100%;
      margin-left: 0px; } }

.termas__item1,
.termas__item2,
.termas__item3 {
  display: flex; }
  @media screen and (max-width: 760px) {
    .termas__item1,
    .termas__item2,
    .termas__item3 {
      flex-direction: column; } }
  .termas__item1 > :first-child,
  .termas__item2 > :first-child,
  .termas__item3 > :first-child {
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 10px;
    padding-top: 6px; }
  .termas__item1 a,
  .termas__item2 a,
  .termas__item3 a {
    position: relative;
    color: #ffffff;
    font-size: 16px !important;
    text-align: center;
    background-color: #3f7fa4;
    border-radius: 2px;
    padding: 5px 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ffffff;
    margin-right: 5px;
    margin-left: 10px; }
    @media screen and (max-width: 960px) {
      .termas__item1 a,
      .termas__item2 a,
      .termas__item3 a {
        margin-left: 0px;
        margin-right: 0px; } }
    .termas__item1 a:hover,
    .termas__item2 a:hover,
    .termas__item3 a:hover {
      background-color: #58b9ee;
      color: #ffffff; }
    .termas__item1 a::before,
    .termas__item2 a::before,
    .termas__item3 a::before {
      position: absolute;
      content: "";
      bottom: 4px;
      left: 5px;
      right: 5px;
      border-radius: 2px; }

.list__category {
  display: flex;
  flex-wrap: wrap;
  text-transform: uppercase !important; }
  .list__category .tag-cloud-link {
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
    display: block;
    min-width: 100px;
    width: fit-content;
    padding: 5px 20px;
    text-align: center;
    border-radius: 2px;
    background: #3f7fa4;
    font-weight: 700;
    line-height: 22px;
    font-size: 12pt !important;
    text-transform: uppercase !important;
    color: #fff;
    -webkit-transition: all .3s;
    transition: all .3s;
    cursor: pointer; }
    .list__category .tag-cloud-link:hover {
      background-color: #58b9ee; }

.knowledge-item__block {
  display: flex;
  position: relative;
  margin-top: -30px;
  margin-left: 3px; }
  .knowledge-item__block-text {
    background: #ffffff;
    border-radius: 2px;
    padding: 2px 5px;
    color: #3f7fa4;
    text-transform: uppercase; }

.knowledge-item__block-plus {
  display: flex;
  align-items: center;
  margin-left: 2px;
  background: #ffffff;
  border-radius: 2px;
  padding: 5px; }

.knowledge-item__block-minus {
  display: flex;
  align-items: center;
  margin-left: 2px;
  background: #ffffff;
  border-radius: 2px;
  padding: 5px; }

.wp-polls-ans .Buttons {
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  min-width: 100px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 20px;
  text-align: center;
  border-radius: 2px;
  background: #3f7fa4;
  font-weight: 700;
  line-height: 22px;
  font-size: 12pt !important;
  text-transform: uppercase !important;
  color: #fff;
  -webkit-transition: all .3s;
  transition: all .3s;
  cursor: pointer; }

.wp-polls-ul {
  display: flex;
  justify-content: center;
  color: #ffffff; }

.wp-polls-ul li:nth-child(1) {
  background-color: #00A660;
  padding: 5px 20px;
  border-radius: 5px;
  margin-left: -20px; }

.wp-polls-ul li:nth-child(2) {
  background-color: #ff3d00;
  padding: 5px 20px;
  border-radius: 5px;
  margin-left: 10px; }

.pollbar {
  border-radius: 5px;
  background-color: #ffffff; }

.wp-polls p {
  margin-bottom: 15px; }

.wp-polls p strong {
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 20px; }

.mob {
  margin-right: 10px;
  width: 190px !important; }
  @media screen and (max-width: 890px) {
    .mob {
      width: 144px !important; } }

.home-table {
  display: flex;
  justify-content: space-evenly; }
  @media screen and (max-width: 992px) {
    .home-table {
      flex-direction: column; } }

.home-table-item {
  border: 1px solid #3f7fa4;
  border-radius: 2px; }
  @media screen and (max-width: 992px) {
    .home-table-item {
      margin-bottom: 10px; } }
  .home-table-item .item__bkmes {
    max-width: 377px;
    padding: 20px;
    margin: 0 auto; }
    .home-table-item .item__bkmes-tittle {
      text-align: center;
      margin: 10px 0;
      font-size: 20px;
      font-weight: 600;
      line-height: 22px;
      text-transform: uppercase;
      color: #474b4f; }
    .home-table-item .item__bkmes-img {
      cursor: pointer;
      max-width: 340px;
      display: flex;
      justify-content: center; }
      .home-table-item .item__bkmes-img img {
        height: 179px; }
  .home-table-item .item__bkbonus {
    max-width: 377px;
    padding: 20px;
    margin: 0 auto; }
    .home-table-item .item__bkbonus-tittle {
      max-width: 300px;
      text-align: center;
      margin: 10px 0;
      font-size: 20px;
      font-weight: 600;
      line-height: 22px;
      text-transform: uppercase;
      color: #474b4f; }
    .home-table-item .item__bkbonus-img {
      cursor: pointer;
      max-width: 340px;
      margin-left: auto;
      margin-right: auto;
      text-align: center; }
      .home-table-item .item__bkbonus-img img {
        height: 179px; }
  .home-table-item .item__star-item p {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 16px; }
    .home-table-item .item__star-item p span {
      position: absolute;
      right: 0;
      background-image: url("./images/star.svg");
      background-repeat: repeat-x;
      background-size: 20px 20px;
      width: 100px;
      height: 20px; }
    .home-table-item .item__star-item p .span {
      position: absolute;
      right: 0;
      background-image: url("./images/star1.svg");
      background-repeat: repeat-x;
      background-size: 20px 20px;
      width: 100px;
      height: 20px; }
  .home-table-item .item__star {
    max-width: 377px;
    padding: 20px;
    margin: 0 auto; }
    .home-table-item .item__star-tittle {
      max-width: 200px;
      text-align: center;
      margin: 10px 0;
      font-size: 20px;
      font-weight: 600;
      line-height: 22px;
      text-transform: uppercase;
      color: #474b4f; }
    .home-table-item .item__star-img {
      cursor: pointer;
      max-width: 340px;
      display: flex;
      justify-content: center; }
      .home-table-item .item__star-img img {
        height: 109px; }

.item__bkmes-button .bkmes-button-top {
  margin-top: 12px;
  padding: 5px 25px;
  font-weight: 600;
  line-height: 20px;
  font-size: 1.28571rem;
  text-align: center;
  border-radius: 2px;
  background-color: #ff3d00;
  cursor: pointer; }
  .item__bkmes-button .bkmes-button-top a {
    color: white;
    text-transform: uppercase; }
  .item__bkmes-button .bkmes-button-top:hover {
    background-color: #dc3705; }
  .item__bkmes-button .bkmes-button-top .bkmes-button-bottom {
    cursor: pointer;
    margin: 10px 0;
    padding: 5px 25px;
    font-weight: 600;
    line-height: 20px;
    font-size: 1.28571rem;
    text-align: center;
    border: 1px solid #3f7fa4; }
    .item__bkmes-button .bkmes-button-top .bkmes-button-bottom a {
      color: #3f7fa4;
      text-transform: uppercase; }
    .item__bkmes-button .bkmes-button-top .bkmes-button-bottom:hover {
      background-color: #e8e8e9; }
      .item__bkmes-button .bkmes-button-top .bkmes-button-bottom:hover a {
        color: #4996c2; }

.bkbonus-button-top {
  margin-top: 25px;
  padding: 10px 25px;
  font-weight: 600;
  line-height: 20px;
  font-size: 1.28571rem;
  text-align: center;
  border-radius: 2px;
  background-color: #3f7fa4;
  cursor: pointer; }
  .bkbonus-button-top a {
    color: white;
    text-transform: uppercase; }
  .bkbonus-button-top:hover {
    background-color: #4996c2; }

.star-button-top {
  margin-top: 15px;
  padding: 5px 25px;
  font-weight: 600;
  line-height: 20px;
  font-size: 1.28571rem;
  text-align: center;
  border-radius: 2px;
  background-color: #3f7fa4;
  cursor: pointer; }
  .star-button-top a {
    color: white;
    text-transform: uppercase; }
  .star-button-top:hover {
    background-color: #4996c2; }

.search-form {
  display: flex;
  position: absolute;
  right: 95px;
  opacity: 0;
  align-items: center;
  transform: scaleX(0);
  transform-origin: right;
  visibility: hidden;
  transition: all 1s, opacity .5s 0s; }
  @media screen and (max-width: 992px) {
    .search-form {
      position: static;
      transform: scaleX(1);
      visibility: visible;
      opacity: 1;
      right: 77px; } }
  .search-form.active {
    transform: scaleX(1);
    visibility: visible;
    opacity: 1;
    transition: all 1s, opacity 1s .5s; }
  .search-form__button {
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: none; }
  .search-form__input {
    width: 810px;
    padding-left: 5px;
    margin: 0 15px;
    background-color: transparent;
    border: none;
    color: white;
    border-bottom: 2px solid white; }
  .search-form__input::placeholder {
    color: white; }
  .search-form__close {
    position: relative;
    width: 20px;
    height: 20px;
    background: transparent;
    border: none;
    cursor: pointer; }
    .search-form__close:hover {
      opacity: 0.6; }
    .search-form__close::before, .search-form__close::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      height: 20px;
      width: 2px;
      background-color: White; }
    .search-form__close::before {
      transform: rotate(45deg); }
    .search-form__close::after {
      transform: rotate(-45deg); }
    @media screen and (max-width: 992px) {
      .search-form__close {
        display: none; } }

@media screen and (max-width: 720px) {
  .post-contentsos .news-item {
    width: 330px !important;
    margin: 10px auto !important; } }

@media screen and (max-width: 320px) {
  .post-contentsos .news-item {
    width: 277px !important;
    margin: 10px auto !important; } }

@media screen and (max-width: 720px) {
  .post-contentsos div {
    margin: 0 auto; } }

.page__author-home {
  margin-bottom: 84px; }

.autor, .tags__img img {
  max-width: 156px; }
  @media screen and (max-width: 768px) {
    .autor, .tags__img img {
      max-width: 275px; } }

.autor, .tags__title {
  text-align: center;
  color: #474b4f;
  padding-top: 71px;
  margin-top: -33px; }

.page__author {
  display: flex;
  font-family: 'Roboto Condensed'; }
  .page__author .author-description-texMob {
    display: none; }
  @media screen and (max-width: 768px) {
    .page__author {
      display: flex;
      flex-direction: column; }
      .page__author .author-description-text {
        display: none; }
      .page__author .author-description-texMob {
        display: block;
        font-family: 'Roboto Condensed';
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
        margin-bottom: 10px; } }

.page__author-description {
  margin-left: 24px; }
  @media screen and (max-width: 768px) {
    .page__author-description {
      margin-left: 0px; } }

.author-description-text {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left; }

.page__author-description p {
  margin-top: 16px;
  font-family: 'Roboto Condensed';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left; }

.page__author-all {
  background: #ecf2f6;
  margin-top: 25px; }

.page__author-box {
  text-align: center;
  padding: 30px 72px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .page__author-box {
      padding: 1em; } }

.author-box-itemBlue {
  min-width: 292px;
  padding: 16px 13px;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #2A5F7D;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #474B4F; }
  @media screen and (max-width: 768px) {
    .author-box-itemBlue {
      width: 100%;
      margin-bottom: 5%; } }
  .author-box-itemBlue p {
    position: relative;
    font-size: 14px; }
    .author-box-itemBlue p::before {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      bottom: -3px;
      height: 1px;
      width: 100%;
      background-color: #2a5f7d;
      margin: 0 auto; }
  .author-box-itemBlue span {
    font-size: 18px;
    padding-top: 11px;
    color: #2a5f7d; }

.author-box-itemGreen {
  min-width: 292px;
  padding: 16px 13px;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #00A660;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #474B4F; }
  @media screen and (max-width: 768px) {
    .author-box-itemGreen {
      width: 100%;
      margin-bottom: 5%; } }
  .author-box-itemGreen p {
    position: relative;
    font-size: 14px; }
    .author-box-itemGreen p::before {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      bottom: -3px;
      height: 1px;
      width: 100%;
      background-color: #00A660;
      margin: 0 auto; }
  .author-box-itemGreen span {
    font-size: 18px;
    padding-top: 11px;
    color: #00A660; }

.author-box-itemRed {
  min-width: 292px;
  padding: 16px 13px;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #FF3D00;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #474B4F; }
  @media screen and (max-width: 768px) {
    .author-box-itemRed {
      width: 100%; } }
  .author-box-itemRed p {
    position: relative;
    font-size: 14px; }
    .author-box-itemRed p::before {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      bottom: -3px;
      height: 1px;
      width: 100%;
      background-color: #FF3D00;
      margin: 0 auto; }
  .author-box-itemRed span {
    font-size: 18px;
    padding-top: 11px;
    color: #FF3D00; }

.prognozy__author a {
  color: #474B4F; }

.api {
  border-top: 1px solid #C4C9CE;
  display: flex;
  flex-direction: column;
  height: 200px;
  margin-bottom: 50px;
  margin-left: 0px;
  margin-right: 215px; }
  @media screen and (max-width: 992px) {
    .api {
      margin-left: 0px;
      margin-right: 0px; } }

.api__box {
  width: 100%; }

.api__box-pm {
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  color: #6c7277;
  border-bottom: 1px solid #C4C9CE;
  border-left: 1px solid #C4C9CE;
  border-right: 1px solid #C4C9CE;
  align-items: center; }
  @media screen and (max-width: 992px) {
    .api__box-pm {
      justify-content: space-around; } }

.api__title {
  font-weight: 400;
  text-transform: uppercase;
  color: #474b4f;
  padding: 10px 0 30px;
  font-size: 1.57143rem; }

.api__box-pm--logo {
  margin-left: 33px;
  padding-top: 10px;
  padding-bottom: 5px;
  max-width: 100px;
  width: 100%; }
  .api__box-pm--logo img {
    max-width: 120px;
    height: 40px; }
    @media screen and (max-width: 992px) {
      .api__box-pm--logo img {
        max-width: 105px;
        height: 35px; } }
  @media screen and (max-width: 992px) {
    .api__box-pm--logo {
      margin-left: -12px; } }

.api__box-pm--p1,
.api__box-pm--p2,
.api__box-pm--x {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .api__box-pm--p1 .api__box-pm--p1-cof,
  .api__box-pm--p2 .api__box-pm--p1-cof,
  .api__box-pm--x .api__box-pm--p1-cof {
    color: #FF3D00; }

.api__box-pm--p1,
.api__box-pm--p2 {
  position: relative; }
  .api__box-pm--p1::before,
  .api__box-pm--p2::before {
    position: absolute;
    left: -50px;
    top: 0;
    content: "";
    height: 100%;
    width: 1px;
    background: #C4C9CE; }
    @media screen and (max-width: 992px) {
      .api__box-pm--p1::before,
      .api__box-pm--p2::before {
        left: -16px; } }
    @media screen and (max-width: 320px) {
      .api__box-pm--p1::before,
      .api__box-pm--p2::before {
        left: -14%; } }
  .api__box-pm--p1::after,
  .api__box-pm--p2::after {
    position: absolute;
    right: -50px;
    top: 0;
    content: "";
    height: 100%;
    width: 1px;
    background: #C4C9CE; }
    @media screen and (max-width: 992px) {
      .api__box-pm--p1::after,
      .api__box-pm--p2::after {
        right: -16px; } }
    @media screen and (max-width: 320px) {
      .api__box-pm--p1::after,
      .api__box-pm--p2::after {
        right: -14%; } }

.api__box-pm--button {
  padding-right: 30px;
  margin-left: -13px; }
  .api__box-pm--button button {
    padding: 5px 50px;
    background: #FF3D00;
    border-radius: 2px;
    border: none;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    color: #FFFFFF; }
    @media screen and (max-width: 992px) {
      .api__box-pm--button button {
        font-size: 9px;
        padding: 5px 5px; } }
  @media screen and (max-width: 992px) {
    .api__box-pm--button {
      padding-right: 0px;
      margin-left: 0px; } }

.header__scroll {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  transition: all .3s ease;
  z-index: 5; }

.table__aside {
  position: fixed;
  top: 0;
  right: 160px;
  bottom: 0;
  max-width: 378px;
  width: 100%;
  height: auto;
  z-index: 5; }
  .table__aside svg {
    width: 50px;
    height: 50px; }
  @media screen and (min-width: 1729px) and (max-width: 1918px) {
    .table__aside {
      right: 90px; } }
  @media screen and (min-width: 1537px) and (max-width: 1729px) {
    .table__aside {
      right: 15px;
      max-width: 338px; } }
  @media screen and (min-width: 1440px) and (max-width: 1537px) {
    .table__aside {
      right: 20px;
      max-width: 230px; } }
  @media screen and (min-width: 1366px) and (max-width: 1440px) {
    .table__aside {
      right: 10px;
      max-width: 186px; } }
  @media screen and (max-width: 1365px) {
    .table__aside {
      display: none; } }

.aside__item {
  margin-top: 2px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 65px;
  max-width: 378px;
  border: 1px solid #3F7FA4;
  border-radius: 2px; }
  @media screen and (min-width: 1366px) and (max-width: 1600px) {
    .aside__item {
      max-width: 328px; } }
  @media screen and (min-width: 968px) and (max-width: 1366px) {
    .aside__item {
      max-width: 189px; } }
  .aside__item h5 {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    text-transform: uppercase;
    color: #000000;
    text-align: center; }
    @media screen and (min-width: 1366px) and (max-width: 1475px) {
      .aside__item h5 {
        font-size: 13px; } }
  .aside__item .aside__item-img {
    background-color: #151e45;
    max-width: 81px;
    width: 100%;
    height: 49px; }
    .aside__item .aside__item-img img {
      width: 100%;
      height: 100%; }
    @media screen and (min-width: 1366px) and (max-width: 1475px) {
      .aside__item .aside__item-img {
        max-width: 71px;
        height: 29px; } }
  .aside__item .aside__item-button {
    display: flex;
    justify-content: center;
    padding: 10px 10px;
    background: #FF3D00;
    border-radius: 2px; }
    .aside__item .aside__item-button:hover {
      background: #eb6942; }
    @media screen and (min-width: 1366px) and (max-width: 1475px) {
      .aside__item .aside__item-button {
        padding: 5px 5px; } }
    .aside__item .aside__item-button a {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      text-transform: uppercase;
      color: #FFFFFF; }
      @media screen and (min-width: 1366px) and (max-width: 1475px) {
        .aside__item .aside__item-button a {
          font-size: 11px; } }

.aside__header {
  display: flex;
  align-items: center;
  max-width: 378px;
  width: 100%;
  height: 42px;
  background: #3F7FA4;
  border-radius: 0px 0px 2px 2px; }
  @media screen and (min-width: 1366px) and (max-width: 1475px) {
    .aside__header {
      max-width: 230px; } }
  .aside__header p {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    margin: 0 auto;
    font-size: 18px;
    line-height: 144%;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF; }

.aside__banner {
  display: flex;
  flex-direction: column;
  margin-top: 2px; }
  .aside__banner img {
    width: 100%; }
    @media screen and (min-width: 1366px) and (max-width: 1475px) {
      .aside__banner img {
        max-width: 230px; } }
  .aside__banner .aside__banner-block {
    position: relative; }
  .aside__banner .aside__text {
    position: absolute;
    bottom: 95px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF; }
    .aside__banner .aside__text span {
      margin-top: 8px;
      font-family: 'Roboto Condensed';
      font-weight: 700;
      font-size: 30px;
      color: #F8FF13;
      letter-spacing: 0px; }
  .aside__banner .aside__ref {
    position: absolute;
    bottom: 31px;
    width: 100%;
    display: flex;
    justify-content: center; }
    .aside__banner .aside__ref p {
      max-width: 266px;
      width: 100%;
      height: 44px;
      background: #FF3D00;
      border-radius: 2px;
      justify-content: center;
      display: flex;
      align-items: center;
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      text-transform: uppercase;
      color: #FFFFFF; }
      .aside__banner .aside__ref p:hover {
        background: #eb6942; }

.header__bonus {
  cursor: pointer; }
  .header__bonus:hover {
    opacity: 0.8; }
  @media screen and (max-width: 968px) {
    .header__bonus {
      position: absolute;
      right: 60px;
      top: 12px; } }

.header__bonus-block {
  position: relative;
  display: none;
  width: 400px;
  margin: auto;
  border: 1px solid #ccc;
  overflow: hidden;
  height: 70px; }

.header__bonus-block .header__bonus {
  width: 100%;
  height: 70px; }

.visible {
  height: auto;
  display: block;
  position: absolute;
  right: 13px;
  top: 74%;
  z-index: 2; }
  @media screen and (max-width: 992px) {
    .visible {
      right: 44px;
      top: 88%; } }

.header__bonus-block {
  border: none;
  max-width: 165px;
  width: 100%; }

.header__bonus-box {
  padding: 12px 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center; }
  .header__bonus-box img {
    width: 117px;
    height: 54px; }
  .header__bonus-box p {
    margin-top: 8px;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #000000; }
  .header__bonus-box a {
    margin-top: 8px;
    width: 117px;
    height: 26px;
    background: #FF3D00;
    border-radius: 2px;
    display: flex;
    align-items: center; }
    .header__bonus-box a:hover {
      background: #eb6942; }
    .header__bonus-box a p {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      margin: 0 auto;
      text-transform: uppercase;
      color: #FFFFFF; }

.poster__body {
  position: relative; }

.poster__button-block {
  position: absolute;
  right: 16px;
  bottom: 16px;
  display: flex; }

.poster__button-left {
  width: 168px;
  height: 44px;
  background-color: #00a660;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  cursor: pointer; }
  .poster__button-left .hovers__block-p-p {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer; }
  .poster__button-left p {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer; }

.poster__button-right {
  width: 168px;
  height: 44px;
  background: #FF3D00;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center; }

.poster__button-right-block {
  display: flex;
  justify-content: space-between; }
  .poster__button-right-block img {
    height: 27px;
    width: 30px;
    margin-right: 10px;
    margin-top: 5px; }

.poster__button-right-block-p {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF; }
  .poster__button-right-block-p span {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 14px;
    align-items: center;
    text-align: center;
    color: #FFFFFF; }

.hovers__block {
  padding-top: 50px;
  position: relative;
  display: none;
  position: absolute;
  top: 100%;
  right: -112%;
  width: 399px;
  height: 225px; }
  @media screen and (max-width: 968px) {
    .hovers__block {
      height: 255px;
      right: -94%;
      width: 329px; } }
  .hovers__block::before {
    content: "";
    position: absolute;
    background: url("./images/bordall.png");
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    top: 19px;
    bottom: 0;
    z-index: -1; }
    @media screen and (max-width: 968px) {
      .hovers__block::before {
        background: url("./images/bordallmob.png");
        background-repeat: no-repeat;
        height: 285px;
        width: 353px; } }

.hovers {
  position: relative;
  z-index: 1; }

.hovers:hover .hovers__block {
  display: block; }

.hovers__block-p {
  max-width: 281px;
  width: 100%;
  margin: 3px auto;
  position: relative;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #6C7074; }
  .hovers__block-p::before {
    content: "";
    position: absolute;
    left: -10px;
    bottom: -20px;
    width: 110%;
    background-color: #EEF1F3;
    height: 1px; }

.hovers__block-items {
  margin-top: 35px;
  display: flex;
  justify-content: space-around; }
  @media screen and (max-width: 968px) {
    .hovers__block-items {
      flex-direction: column;
      justify-content: space-around;
      width: 340px;
      padding: 5px 30px;
      margin: 0 auto;
      padding-top: 35px; } }

.hovers__block-item {
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media screen and (max-width: 968px) {
    .hovers__block-item {
      flex-direction: row;
      justify-content: center;
      padding: 5px 0px;
      width: 100%; } }
  .hovers__block-item img {
    width: 66px;
    height: 41px;
    background-color: #151e45; }
  .hovers__block-item p {
    margin-top: 8px;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    width: 100%; }
  .hovers__block-item a {
    margin-top: 8px;
    width: 101px;
    height: 26px;
    background-color: #FF3D00;
    border-radius: 2px; }
    .hovers__block-item a:hover {
      background: #eb6942; }
    .hovers__block-item a p {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 12px;
      justify-content: center;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: #FFFFFF; }
      @media screen and (max-width: 968px) {
        .hovers__block-item a p {
          padding: 0 13px; } }

.banner__butosnov-block {
  margin-top: 100px;
  height: 91px;
  max-width: 1164px;
  width: 100%;
  position: fixed;
  bottom: -6px;
  z-index: 10; }
  @media screen and (max-width: 968px) {
    .banner__butosnov-block {
      left: 0;
      bottom: -3px; } }

.butosno {
  position: relative; }

.banner__butosnov-box {
  max-width: 1164px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media screen and (max-width: 968px) {
    .banner__butosnov-box {
      flex-direction: column;
      height: 88px;
      justify-content: center; } }

.banner__butosnov-button {
  display: flex;
  align-items: center; }

.banner__butosnov-zif {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF; }
  .banner__butosnov-zif span {
    font-size: 28px;
    line-height: 33px;
    color: #FF3D00; }

@media screen and (max-width: 968px) {
  .banner__butosnov-logo,
  .banner__butosnov-reiting,
  .banner__butosnov-zif {
    display: none; } }

.banner__butosnov-reiting {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  align-items: center;
  text-transform: uppercase;
  color: #FFFFFF; }

.banner__butosnov-logo {
  margin: 0px 30px;
  min-height: 65px; }
  .banner__butosnov-logo img {
    max-width: 150px;
    width: 100%;
    height: 100%; }

.banner__butosnov-button {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  text-transform: uppercase; }

.banner__butosnov-button-left {
  color: #FFFFFF;
  margin-right: 24px;
  width: 190px;
  height: 44px;
  background: #FF3D00;
  border-radius: 2px;
  display: flex;
  align-items: center; }
  .banner__butosnov-button-left p {
    margin: 0 auto; }
  @media screen and (max-width: 968px) {
    .banner__butosnov-button-left {
      width: 130px;
      height: 44px;
      margin-right: 8px; } }

.banner__butosnov-button-right {
  color: #FFFFFF;
  margin-right: 35px;
  width: 190px;
  height: 44px;
  background: #00A660;
  border-radius: 2px;
  display: flex;
  align-items: center; }
  .banner__butosnov-button-right p {
    margin: 0 auto; }
  @media screen and (max-width: 968px) {
    .banner__butosnov-button-right {
      width: 130px;
      height: 44px;
      margin-right: 0px;
      margin-left: 8px; } }

.bonus-item-box {
  display: flex;
  justify-content: space-around; }
  @media screen and (max-width: 968px) {
    .bonus-item-box {
      flex-direction: column;
      width: 100%; } }

.bonus-item {
  margin: 10px auto; }

.banner__buttonpm-logo {
  max-width: 200px;
  width: 100%;
  margin-left: 50px; }
  @media screen and (max-width: 968px) {
    .banner__buttonpm-logo {
      max-width: 100px;
      width: 100%;
      margin-left: 0px; } }
  .banner__buttonpm-logo img {
    max-width: 220px;
    width: 100%;
    height: 100%; }
    @media screen and (max-width: 968px) {
      .banner__buttonpm-logo img {
        max-width: 100px;
        width: 100%;
        margin-left: 5px; } }

.banner__buttonpm-block {
  margin-top: 100px;
  height: 91px;
  max-width: 1164px;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 10; }
  @media screen and (max-width: 968px) {
    .banner__buttonpm-block {
      left: 0;
      height: 85px;
      bottom: 0;
      margin-top: 0px; } }

#banner__buttonpm-img {
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 11; }

.banner__buttonpm-box {
  max-width: 1164px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px; }
  @media screen and (max-width: 968px) {
    .banner__buttonpm-box {
      height: 85px; } }

.banner__buttonpm-text {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-left: 100px; }
  @media screen and (max-width: 968px) {
    .banner__buttonpm-text {
      margin-left: 0px; } }

.banner__buttonpm-bon {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 45px;
  align-items: center;
  text-align: center;
  text-transform: uppercase; }
  @media screen and (max-width: 968px) {
    .banner__buttonpm-bon {
      margin-bottom: 5px;
      font-size: 24px; } }
  .banner__buttonpm-bon span {
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF; }
    @media screen and (max-width: 968px) {
      .banner__buttonpm-bon span {
        display: none; } }

.banner__buttonpm-button {
  max-width: 293px;
  width: 100%;
  height: 56px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  margin-right: 40px; }
  @media screen and (max-width: 968px) {
    .banner__buttonpm-button {
      margin-right: 10px;
      max-width: 139px;
      height: 30px; } }
  .banner__buttonpm-button P {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    margin: 0 auto; }
    @media screen and (max-width: 968px) {
      .banner__buttonpm-button P {
        font-size: 17px; } }

@media screen and (max-width: 968px) {
  .banner__buttonpm-text,
  .banner__buttonpm-butto {
    display: none; }
  .poster__rating,
  .poster__logo {
    height: 88px;
    width: 144px;
    margin-bottom: 10px; } }

@media screen and (max-width: 968px) {
  .styled-text .read-more-btn {
    position: absolute;
    bottom: 115px;
    width: 130px; } }

.rating {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 290px; }
  @media screen and (max-width: 544px) {
    .rating.sm {
      min-width: 125px; }
      .rating.sm .rating__container {
        max-width: 90px; } }
  .rating__container {
    max-width: 110px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative; }
  .rating__stars {
    position: relative;
    padding-top: 20%;
    width: 100%;
    display: block; }
    .rating__stars_all {
      position: absolute;
      width: 200%;
      height: 100%;
      top: 0;
      overflow: hidden;
      background: url("images/star.svg") left top/auto 200% repeat-x; }
    .rating__stars_current {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      overflow: hidden;
      z-index: 1;
      background: url("images/star.svg") left bottom/auto 200% repeat-x; }
  .rating__text {
    margin-left: 117px; }

.header {
  width: 100%; }
  .header__body {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #3F7FA4;
    padding: 25px 30px;
    border-radius: 2px;
    border-bottom: 4px solid #2A5F7D; }
    @media screen and (max-width: 768px) {
      .header__body {
        padding: 15px 22px; } }
  .header__logo {
    max-width: 165px; }
    @media screen and (max-width: 768px) {
      .header__logo {
        max-width: 110px; } }
    .header__logo img {
      width: 100%;
      vertical-align: top; }
  .header__menu {
    padding-left: 0px; }
    @media screen and (max-width: 992px) {
      .header__menu {
        display: none;
        padding-left: 0;
        z-index: 10; } }
  .header__burger {
    display: none; }
    @media screen and (max-width: 992px) {
      .header__burger {
        display: block; } }
  @media screen and (max-width: 992px) {
    .header__search {
      order: 1;
      width: 100%; } }
  .header__.search-form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    left: 0;
    right: 95px;
    opacity: 0;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: right;
    transform-origin: right;
    visibility: hidden;
    -webkit-transition: all 1s, opacity .5s 0s;
    transition: all 1s, opacity .5s 0s; }
  .header__search-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url(./images/search.svg);
    background-repeat: no-repeat;
    background-position: center;
    vertical-align: middle;
    cursor: pointer; }
    .header__search-icon:hover {
      opacity: 0.6; }
    @media screen and (max-width: 992px) {
      .header__search-icon {
        display: none; } }

.scale-hide {
  -webkit-transition: all .3s;
  transition: all .3s;
  -webkit-transform: scale(0);
  transform: scale(0); }

.scale-show {
  -webkit-transition: all .3s .5s;
  transition: all .3s .5s;
  -webkit-transform: scale(1);
  transform: scale(1); }

.ad {
  margin-bottom: 20px;
  margin-top: 0px; }
  @media screen and (max-width: 768px) {
    .ad {
      margin-bottom: 10px; } }
  .ad__body {
    display: flex;
    align-items: initial;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-height: 180px;
    overflow: hidden;
    border-radius: 2px;
    cursor: pointer; }
    @media screen and (max-width: 768px) {
      .ad__body {
        height: 120px; } }
    .ad__body img {
      width: 100%;
      min-width: 100%;
      height: 100%;
      object-fit: cover; }

.footer {
  width: 100%;
  margin-bottom: 80px; }
  .footer__body {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #3F7FA4;
    padding: 10px 30px;
    border-radius: 2px;
    border-top: 4px solid #2A5F7D;
    color: white; }
    @media screen and (max-width: 768px) {
      .footer__body {
        flex-direction: column-reverse;
        align-items: flex-start;
        padding: 22px; } }
  .footer__logo {
    max-width: 120px;
    margin-right: 3%; }
    @media screen and (max-width: 768px) {
      .footer__logo {
        max-width: 300px;
        margin-bottom: 10px;
        margin-right: 0px;
        text-align: center; } }
    .footer__logo img {
      max-height: 40px;
      min-height: 40px;
      height: 100%;
      width: auto;
      vertical-align: top; }
  .footer__menu {
    padding-right: 30px;
    padding-bottom: 21px; }
    @media screen and (max-width: 768px) {
      .footer__menu {
        padding-right: 0;
        width: 100%;
        order: 1; } }
  .footer__copyright {
    padding: 20px 0;
    font-weight: normal;
    line-height: 14px;
    font-size: 0.85714rem;
    text-transform: initial;
    color: #C4C9CE; }

.logo__box {
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 3%; }
  @media screen and (max-width: 768px) {
    .logo__box {
      margin-right: 0px;
      width: 100%;
      flex-direction: column; } }
  .logo__box a {
    width: 100%; }

.faq__title {
  padding: 100px 20px 30px;
  font-weight: normal;
  line-height: 37px;
  font-size: 2.28571rem;
  text-transform: initial;
  color: #000000; }
  @media screen and (max-width: 768px) {
    .faq__title {
      padding: 50px 0 30px; } }

.faq__body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 100px;
  margin-bottom: 31px; }
  @media screen and (max-width: 992px) {
    .faq__body {
      padding: 0 60px; } }
  @media screen and (max-width: 768px) {
    .faq__body {
      padding: 0; } }

.faq__item {
  margin-bottom: 30px; }
  .faq__item:last-of-type {
    margin-bottom: 0; }

.faq-item {
  width: 100%;
  padding: 30px;
  border: 1px solid #C4C9CE;
  border-radius: 2px;
  cursor: pointer; }
  .faq-item:hover .faq-item__header::after {
    background-color: #6C7074; }
  .faq-item.active .faq-item__header::after {
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='4' viewBox='0 0 12 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='12' height='4' fill='%23C4C9CE'/%3E%3C/svg%3E%0A"); }
  .faq-item__header {
    position: relative;
    padding-right: 40px;
    font-weight: bold;
    line-height: 26px;
    font-size: 1.28571rem;
    text-transform: initial;
    color: #6C7277; }
    .faq-item__header::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 26px;
      height: 26px;
      border-radius: 2px;
      background: #474B4F url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4 8L4 12H8V8H12V4H8V0H4L4 4H0V8H4Z' fill='%23C4C9CE'/%3E%3C/svg%3E%0A") center no-repeat;
      transition: all .3s; }
  .faq-item__body {
    display: none;
    margin-top: 25px; }

.list {
  padding-bottom: 60px; }
  .list.with-pagination {
    padding-bottom: 0; }
    .list.with-pagination .list__body {
      padding-bottom: 40px; }
  .list.pt {
    padding-top: 60px; }
  .list__body {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr)); }
  .list__button {
    margin: 40px auto 0;
    min-width: 280px; }

.table {
  padding-bottom: 60px; }
  .table__header {
    margin-bottom: 15px;
    padding: 6px 0; }
  .table .table-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    text-align: center;
    background-color: #3F7FA4;
    border-radius: 2px;
    font-weight: normal;
    line-height: 16px;
    font-size: 0.85714rem;
    text-transform: initial;
    color: #FFFFFF; }
    @media screen and (max-width: 992px) {
      .table .table-header {
        display: none; } }
    .table .table-header__logo {
      min-width: 230px; }
    .table .table-header__rating {
      min-width: 130px; }
    .table .table-header__bonus {
      min-width: 255px; }
    .table .table-header__review {
      min-width: 85px; }
    .table .table-header__apps {
      min-width: 100px; }
  .table__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    transition: all .3s; }
    @media screen and (max-width: 992px) {
      .table__body {
        display: grid;
        grid-gap: 30px;
        grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
        align-items: stretch;
        justify-content: flex-start; } }
  .table__row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100px;
    min-height: 100px;
    margin-bottom: 15px;
    border-radius: 2px;
    overflow: hidden; }
    .table__row:last-of-type {
      margin-bottom: 0; }
    @media screen and (max-width: 992px) {
      .table__row {
        flex-direction: column;
        height: auto;
        margin-bottom: 0; } }
  .table__title_mobile {
    display: none;
    width: 100%;
    background-color: #6C7074;
    padding: 2px;
    text-align: center;
    font-weight: normal;
    line-height: 16px;
    font-size: 0.85714rem;
    text-transform: initial;
    color: #FFFFFF; }
    @media screen and (max-width: 992px) {
      .table__title_mobile {
        display: block; } }
  .table__logo {
    position: relative;
    min-width: 230px;
    height: 100%;
    padding: 5px; }
    @media screen and (max-width: 992px) {
      .table__logo {
        width: 100%;
        height: 100px; } }
    .table__logo img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .table__position {
    position: absolute;
    top: 0;
    left: 0;
    width: 25px;
    height: 25px;
    background-color: #474B4F;
    border-radius: 2px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    line-height: 21px;
    font-size: 1.28571rem;
    text-transform: initial;
    color: #FFFFFF; }
  .table__rating {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 130px;
    height: 100%;
    border: 1px solid #C4C9CE;
    border-left: none;
    font-weight: normal;
    line-height: 33px;
    font-size: 2rem;
    text-transform: initial;
    color: #C4C9CE; }
    @media screen and (max-width: 992px) {
      .table__rating {
        width: 100%;
        height: auto;
        padding: 6px;
        border-left: 1px solid #C4C9CE;
        border-top: none; } }
    .table__rating strong {
      color: #000000; }
  .table__bonus {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 255px;
    height: 100%;
    border: 1px solid #C4C9CE;
    border-left: none;
    padding: 10px; }
    @media screen and (max-width: 992px) {
      .table__bonus {
        width: 100%;
        border-left: 1px solid #C4C9CE;
        border-top: none; } }
  .table__bonus-title {
    font-weight: normal;
    line-height: 22px;
    font-size: 1rem;
    text-transform: initial;
    color: #000000; }
  .table__bonus-value {
    font-weight: bold;
    line-height: 22px;
    font-size: 2.14286rem;
    text-transform: initial;
    color: #000000; }
  .table__bonus-button {
    margin-top: 4px; }
  .table__review {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 85px;
    height: 100%;
    border: 1px solid #C4C9CE;
    border-left: none;
    padding: 10px;
    cursor: pointer; }
    @media screen and (max-width: 992px) {
      .table__review {
        width: 100%;
        height: auto;
        border-left: 1px solid #C4C9CE;
        border-top: none;
        order: 1; } }
  .table__review-title {
    display: none;
    padding-right: 7px;
    font-weight: normal;
    line-height: 22px;
    font-size: 1rem;
    text-transform: initial;
    color: #6C7277; }
    @media screen and (max-width: 992px) {
      .table__review-title {
        display: inline-block; } }
  .table__review-img {
    display: inline-block;
    width: 30px;
    height: 24px;
    background: transparent url("data:image/svg+xml,%3Csvg width='30' height='24' viewBox='0 0 30 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.57142 0C3.46685 0 2.57142 0.895433 2.57142 2V17.0147L-3.40376e-06 23.1342L6.0987 20.5714H28C29.1046 20.5714 30 19.676 30 18.5714V2C30 0.895431 29.1046 0 28 0H4.57142Z' fill='%23C4C9CE'/%3E%3C/svg%3E%0A") center no-repeat; }
  .table__apps {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    height: 100%;
    border: 1px solid #C4C9CE;
    border-left: none;
    padding: 10px; }
    @media screen and (max-width: 992px) {
      .table__apps {
        width: 100%;
        height: auto;
        border-left: 1px solid #C4C9CE;
        border-top: none; } }
  .table__apps-android, .table__apps-ios {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 2px;
    margin: 4px;
    background-color: #474B4F;
    transition: all .3s;
    cursor: pointer; }
    .table__apps-android::after, .table__apps-ios::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .table__apps-android:hover, .table__apps-ios:hover {
      background-color: #6C7074; }
    @media screen and (max-width: 992px) {
      .table__apps-android, .table__apps-ios {
        width: 48px;
        height: 48px; } }
  .table__apps-android::after {
    background: transparent url("data:image/svg+xml,%3Csvg width='24' height='13' viewBox='0 0 24 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.5228 9.71311C16.9713 9.71311 16.5232 9.28225 16.5232 8.75202C16.5232 8.22179 16.9713 7.79092 17.5228 7.79092C18.0743 7.79092 18.5225 8.22179 18.5225 8.75202C18.5225 9.28137 18.0743 9.71311 17.5228 9.71311ZM6.47719 9.71311C5.92569 9.71311 5.47754 9.28225 5.47754 8.75202C5.47754 8.22179 5.92569 7.79092 6.47719 7.79092C7.0287 7.79092 7.47685 8.22179 7.47685 8.75202C7.47593 9.28137 7.02778 9.71311 6.47719 9.71311ZM17.8813 3.92544L19.8788 0.599865C19.9931 0.409053 19.9255 0.164603 19.727 0.0538088C19.5285 -0.0561059 19.2743 0.00896359 19.159 0.199776L17.1368 3.56756C15.5903 2.88873 13.8534 2.51062 12.0005 2.51062C10.1475 2.51062 8.41066 2.88873 6.86407 3.56756L4.84097 0.199776C4.72573 0.00896359 4.47148 -0.0569852 4.27301 0.0538088C4.07454 0.164603 4.00595 0.408174 4.12118 0.599865L6.11867 3.92544C2.68892 5.71837 0.342975 9.05626 0 13H24C23.6561 9.05626 21.3111 5.71837 17.8813 3.92544Z' fill='%23C4C9CE'/%3E%3C/svg%3E%0A") center no-repeat;
    display: inline-block;
    width: 24px;
    height: 13px; }
    @media screen and (max-width: 992px) {
      .table__apps-android::after {
        background-image: url("data:image/svg+xml,%3Csvg width='40' height='22' viewBox='0 0 40 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28.8365 16.341C27.9541 16.341 27.2371 15.6516 27.2371 14.8032C27.2371 13.9548 27.9541 13.2655 28.8365 13.2655C29.7189 13.2655 30.436 13.9548 30.436 14.8032C30.436 15.6502 29.7189 16.341 28.8365 16.341ZM11.1636 16.341C10.2811 16.341 9.5641 15.6516 9.5641 14.8032C9.5641 13.9548 10.2811 13.2655 11.1636 13.2655C12.046 13.2655 12.763 13.9548 12.763 14.8032C12.7615 15.6502 12.0445 16.341 11.1636 16.341ZM29.4102 7.0807L32.6062 1.75977C32.7891 1.45447 32.6808 1.06335 32.3632 0.886082C32.0457 0.710218 31.6389 0.81433 31.4545 1.11963L28.219 6.50809C25.7445 5.42195 22.9655 4.81698 20.0008 4.81698C17.036 4.81698 14.2571 5.42195 11.7826 6.50809L8.54561 1.11963C8.36122 0.81433 7.95441 0.708811 7.63686 0.886082C7.31931 1.06335 7.20956 1.45307 7.39394 1.75977L10.5899 7.0807C5.10232 9.94938 1.34881 15.29 0.800049 21.6H39.2C38.6498 15.29 34.8978 9.94938 29.4102 7.0807Z' fill='%23C4C9CE'/%3E%3C/svg%3E%0A");
        width: 40px;
        height: 22px; } }
  .table__apps-ios::after {
    background: transparent url("data:image/svg+xml,%3Csvg width='19' height='23' viewBox='0 0 19 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.0543 12.41C15.0264 9.61273 17.3435 8.25038 17.4496 8.18897C16.1375 6.27386 14.1051 6.01145 13.3905 5.9947C11.682 5.81603 10.0237 7.01646 9.15826 7.01646C8.2705 7.01646 6.93049 6.01145 5.48439 6.03936C3.62513 6.06728 1.88869 7.14487 0.933931 8.81431C-1.03142 12.2202 0.431426 17.2285 2.31861 19.9811C3.2622 21.3267 4.36213 22.8342 5.80823 22.7839C7.22083 22.7225 7.74567 21.885 9.4486 21.885C11.1348 21.885 11.6317 22.7839 13.1057 22.7504C14.6188 22.7281 15.5736 21.3937 16.4837 20.0369C17.5724 18.4903 18.0135 16.9716 18.0303 16.8879C17.9856 16.8767 15.0822 15.7656 15.0543 12.41Z' fill='%23C4C9CE'/%3E%3Cpath d='M12.2737 4.18043C13.033 3.23126 13.5523 1.94149 13.4071 0.629395C12.3072 0.679645 10.9337 1.38874 10.1464 2.31558C9.44851 3.13634 8.81759 4.47635 8.97951 5.7382C10.2134 5.82753 11.4809 5.11286 12.2737 4.18043Z' fill='%23C4C9CE'/%3E%3C/svg%3E%0A") center no-repeat;
    display: inline-block;
    width: 19px;
    height: 23px; }
    @media screen and (max-width: 992px) {
      .table__apps-ios::after {
        background-image: url("data:image/svg+xml,%3Csvg width='30' height='36' viewBox='0 0 30 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.687 19.056C24.6423 14.5804 28.3497 12.4006 28.5194 12.3024C26.4201 9.2382 23.1683 8.81833 22.0249 8.79153C19.2912 8.50566 16.638 10.4263 15.2533 10.4263C13.8329 10.4263 11.6889 8.81833 9.37513 8.86299C6.4003 8.90766 3.622 10.6318 2.09439 13.3029C-1.05018 18.7523 1.29038 26.7656 4.30988 31.1697C5.81962 33.3227 7.57951 35.7347 9.89326 35.6543C12.1534 35.5561 12.9932 34.216 15.7179 34.216C18.4158 34.216 19.2108 35.6543 21.5692 35.6007C23.9902 35.565 25.5178 33.4299 26.974 31.2591C28.716 28.7845 29.4217 26.3546 29.4485 26.2206C29.3771 26.2028 24.7317 24.425 24.687 19.056Z' fill='%23C4C9CE'/%3E%3Cpath d='M20.238 5.88869C21.4529 4.37001 22.2838 2.30639 22.0515 0.207031C20.2916 0.287432 18.094 1.42198 16.8344 2.90493C15.7177 4.21814 14.7082 6.36216 14.9673 8.38111C16.9416 8.52405 18.9695 7.38057 20.238 5.88869Z' fill='%23C4C9CE'/%3E%3C/svg%3E%0A");
        width: 30px;
        height: 36px; } }
  .table__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    border: 1px solid #C4C9CE;
    border-left: none;
    padding: 10px; }
    @media screen and (max-width: 1120px) {
      .table__buttons {
        flex-wrap: wrap; } }
    @media screen and (max-width: 992px) {
      .table__buttons {
        width: 100%;
        height: auto;
        border-left: 1px solid #C4C9CE;
        border-top: none;
        padding: 20px;
        order: 2; } }
  .table__button {
    width: 100%; }
    .table__button_ref {
      width: 100%;
      margin-left: 10px; }
      @media screen and (max-width: 1120px) {
        .table__button_ref {
          margin-left: 0; } }
      @media screen and (max-width: 992px) {
        .table__button_ref {
          margin-top: 0px; } }
  .table__more-button {
    margin: 25px auto 0;
    min-width: 280px; }

.table-header-mob {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  text-align: center;
  background-color: #3F7FA4;
  border-radius: 2px;
  display: none;
  font-weight: normal;
  line-height: 16px;
  font-size: 0.85714rem;
  text-transform: initial;
  color: #FFFFFF; }
  @media screen and (max-width: 992px) {
    .table-header-mob {
      display: flex;
      width: 100%;
      justify-content: flex-end; } }
  .table-header-mob__logo {
    min-width: 88px; }
  .table-header-mob__rating {
    min-width: 88px; }
  .table-header-mob__bonus {
    min-width: 94px; }
  .table-header-mob__review {
    min-width: 85px; }
  .table-header-mob__apps {
    min-width: 100px; }

.page-404 {
  padding: 100px 0; }
  @media screen and (max-width: 768px) {
    .page-404 {
      padding: 50px 0; } }

.item-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px; }
  @media screen and (max-width: 768px) {
    .item-404 {
      flex-direction: column; } }
  .item-404__text .code-404 {
    text-align: center;
    font-weight: bold;
    line-height: 150px;
    font-size: 10rem;
    text-transform: initial;
    color: #000000; }
    @media screen and (max-width: 544px) {
      .item-404__text .code-404 {
        font-size: 100px;
        line-height: 120px; } }
  .item-404__text .text-404 {
    text-align: center;
    font-weight: bold;
    line-height: 37px;
    font-size: 2.14286rem;
    text-transform: initial;
    color: #6C7277; }
    @media screen and (max-width: 544px) {
      .item-404__text .text-404 {
        font-size: 20px;
        line-height: 25px; } }

.err-text {
  text-align: center;
  margin: 0 auto 30px;
  max-width: 500px;
  font-weight: bold;
  line-height: 18px;
  font-size: 1rem;
  text-transform: initial;
  color: #6C7277; }

.err-link {
  margin: 0 auto;
  min-width: 276px;
  width: fit-content; }
  @media screen and (max-width: 544px) {
    .err-link {
      width: 100%; } }

.styled-text {
  position: relative; }
  .styled-text .otzyvy-text {
    height: 240px;
    overflow: hidden;
    margin-bottom: 40px; }
    .styled-text .otzyvy-text.active {
      height: auto; }
  .styled-text .read-more-btn {
    position: absolute;
    right: 9%;
    bottom: 9px;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer; }
    .styled-text .read-more-btn::before {
      content: 'Читать дальше';
      display: inline-block; }
    .styled-text .read-more-btn.active::before {
      content: 'Свернуть'; }

.comment {
  max-width: 945px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  border: 1px solid #C4C9CE;
  list-style: none;
  border-radius: 2px; }
  .comment-form {
    display: flex;
    flex-direction: column;
    max-width: 945px;
    margin-left: auto;
    margin-right: auto; }
    .comment-form .logged-in-as {
      order: 0;
      margin-bottom: 10px; }
      .comment-form .logged-in-as-user {
        color: #3F7FA4;
        font-weight: bold; }
      .comment-form .logged-in-as a {
        color: #FF3D00; }
    .comment-form textarea,
    .comment-form .comment-form-author input {
      width: 100%;
      resize: none;
      border: 1px solid #C4C9CE;
      border-radius: 2px;
      background: #EEF1F3;
      color: #474B4F;
      border-radius: 2px; }
    .comment-form textarea {
      font-size: 14px;
      line-height: 22px;
      padding: 10px 26px;
      border-radius: 2px; }
    .comment-form-disadvantages {
      order: 4;
      margin-bottom: 20px;
      display: flex;
      padding-left: 20px;
      position: relative; }
      .comment-form-disadvantages::before {
        content: '';
        display: block;
        width: 10px;
        height: 100%;
        background: #C50000;
        border-radius: 2px;
        position: absolute;
        left: 0;
        top: 0; }
    .comment-form .author {
      padding: 4px 13px;
      line-height: 22px;
      max-width: 360px;
      margin-bottom: 20px; }
    .comment-form-comment {
      order: 3;
      margin-bottom: 20px;
      display: flex;
      padding-left: 20px;
      position: relative; }
      .comment-form-comment::before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 100%;
        background: #00A660;
        border-radius: 2px;
        position: absolute;
        left: 0;
        top: 0; }
    .comment-form-rating {
      order: 1;
      margin-bottom: 20px;
      position: relative; }
      .comment-form-rating .fl {
        display: flex; }
      .comment-form-rating .commentratingbox input {
        display: none; }
      .comment-form-rating .commentratingbox-text {
        order: 3; }
    .comment-form-author {
      order: -2; }
  .comment-title-advantages {
    color: #00A660;
    font-weight: bold;
    margin-bottom: 3px; }
  .comment-title-disadvantages {
    color: #C50000;
    font-weight: bold;
    margin-bottom: 3px;
    margin-top: 15px; }
  .comment-rating {
    margin: 35px 0;
    font-weight: normal;
    line-height: 33px;
    font-size: 2rem;
    text-transform: initial;
    color: #C4C9CE; }
    .comment-rating strong {
      color: #474B4F; }
  .comment-author {
    overflow: hidden;
    border: 1px solid #6C7277;
    border-radius: 2px; }
    .comment-author img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align: top; }
  .comment .comment-meta {
    display: flex;
    align-items: center; }
    .comment .comment-meta-info {
      display: flex;
      flex-direction: column;
      margin-left: 10px; }
    .comment .comment-meta-author {
      font-weight: bold;
      line-height: 18px;
      font-size: 1.14286rem;
      text-transform: initial;
      color: #474B4F;
      margin-bottom: 3px; }
    .comment .comment-meta-date {
      font-weight: normal;
      line-height: 14px;
      font-size: 0.85714rem;
      text-transform: initial;
      color: #6C7277; }
  .comment-change {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .comment-change a {
      font-weight: normal;
      line-height: 18px;
      font-size: 1.14286rem;
      text-transform: initial;
      color: #3F7FA4; }
      .comment-change a:hover {
        text-decoration: underline; }
  .comment-reply-title {
    font-size: 32px;
    line-height: 37px;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 44px; }
  .comment-awaiting-moderation {
    margin-bottom: 20px; }

.form-submit {
  order: 6; }

.submit {
  margin: 20px auto 0; }

.comments-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px; }
  @media screen and (max-width: 768px) {
    .comments-pagination {
      margin-bottom: 50px; } }
  .comments-pagination .page-numbers {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    border: 1px solid #000000;
    margin: 0 3px;
    width: 24px;
    height: 20px;
    text-align: center;
    transition: all .3s;
    font-weight: normal;
    line-height: 22px;
    font-size: 1rem;
    text-transform: initial;
    color: #000000; }
    .comments-pagination .page-numbers:hover {
      background: #C4C9CE; }
  .comments-pagination .next,
  .comments-pagination .dots,
  .comments-pagination .prev {
    border: none;
    width: auto;
    height: auto;
    font-weight: normal;
    line-height: 12px;
    font-size: 0.85714rem;
    text-transform: initial;
    color: #000000; }
    .comments-pagination .next:hover,
    .comments-pagination .dots:hover,
    .comments-pagination .prev:hover {
      background: transparent;
      color: #000000; }
  .comments-pagination .next,
  .comments-pagination .prev {
    padding: 0 15px; }
  .comments-pagination .prev::before {
    content: '←';
    opacity: 0;
    padding-right: 5px;
    transition: all .3s; }
  .comments-pagination .prev:hover::before {
    opacity: 1; }
  .comments-pagination .next::after {
    content: '→';
    opacity: 0;
    padding-left: 5px;
    transition: all .3s; }
  .comments-pagination .next:hover::after {
    opacity: 1; }
  .comments-pagination .dots {
    cursor: default; }
  .comments-pagination .current {
    background: #000000;
    color: #FFFFFF;
    cursor: default; }
    .comments-pagination .current:hover {
      background: #000000; }

.rate-top {
  display: flex;
  align-items: center; }
  .rate-top .fl {
    display: flex;
    align-items: center;
    justify-content: center;
    order: -1;
    margin-right: 20px; }
  .rate-top .cm-placeholder {
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    color: #474B4F;
    background: #EEF1F3;
    border: 1px solid #C4C9CE;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .rate-top .dwn {
    background: #C4C9CE;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
    .rate-top .dwn::after {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 20px 10px 0;
      border-color: #FFFFFF transparent transparent transparent; }

.commentratingbox {
  display: flex;
  flex-direction: column;
  width: 50px;
  position: absolute;
  z-index: 3;
  display: none; }
  .commentratingbox.active {
    display: flex; }
  .commentratingbox label {
    background-color: #FFFFFF;
    padding: 2px;
    font-size: 14px;
    border: 1px solid #C4C9CE;
    text-align: center;
    cursor: pointer; }
    .commentratingbox label:hover {
      background-color: #EEF1F3; }
  .commentratingbox input {
    display: none; }

.mo-openid-app-icons {
  margin: 0 0 20px !important;
  order: 5; }
  .mo-openid-app-icons p {
    font-weight: normal;
    line-height: 22px;
    font-size: 1.28571rem;
    color: #6C7277 !important; }
  .mo-openid-app-icons a {
    margin-left: -4px;
    display: inline-block;
    cursor: pointer; }
