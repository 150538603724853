.home-table {
  //margin-bottom: 50px;
  display: flex;
  justify-content: space-evenly;

  //grid-gap: 30px;
  //grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  @media screen and (max-width: 992px) {
    flex-direction: column;
  }

}

.home-table-item {
  border: 1px solid #3f7fa4;
  border-radius: 2px;

  @media screen and (max-width: 992px) {
    margin-bottom: 10px;
  }

  .item__bkmes {
    max-width: 377px;
    padding: 20px;
    margin: 0 auto;

    &-tittle {
      text-align: center;
      margin: 10px 0;
      font-size: 20px;
      font-weight: 600;
      line-height: 22px;
      text-transform: uppercase;
      color: #474b4f;
    }

    &-img {
      cursor: pointer;
      max-width: 340px;
      display: flex;
      justify-content: center;

      img {

        height: 179px;
      }
    }
  }

  .item__bkbonus {
    max-width: 377px;
    padding: 20px;
    margin: 0 auto;

    &-tittle {
      max-width: 300px;
      text-align: center;
      margin: 10px 0;
      font-size: 20px;
      font-weight: 600;
      line-height: 22px;
      text-transform: uppercase;
      color: #474b4f;
    }

    &-img {
      cursor: pointer;
      max-width: 340px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;

      img {
        height: 179px;
      }
    }
  }


  .item__star-item {


    p {
      position: relative;
      display: flex;
      align-items: center;
      font-size: 16px;

      span {
        position: absolute;
        right: 0;
        background-image: url('./images/star.svg');
        background-repeat: repeat-x;
        background-size: 20px 20px;
        width: 100px;
        height: 20px;
      }

      .span {
        position: absolute;
        right: 0;
        background-image: url('./images/star1.svg');
        background-repeat: repeat-x;
        background-size: 20px 20px;
        width: 100px;
        height: 20px;
      }
    }
  }

  .item__star {
    max-width: 377px;
    padding: 20px;
    margin: 0 auto;

    &-tittle {
      max-width: 200px;
      text-align: center;
      margin: 10px 0;
      font-size: 20px;
      font-weight: 600;
      line-height: 22px;
      text-transform: uppercase;
      color: #474b4f;
    }

    &-img {
      cursor: pointer;
      max-width: 340px;
      display: flex;
      justify-content: center;

      img {
        height: 109px;
      }
    }
  }
}



.item__bkmes-button {

  .bkmes-button-top {
    margin-top: 12px;
    padding: 5px 25px;
    font-weight: 600;
    line-height: 20px;
    font-size: 1.28571rem;
    text-align: center;
    border-radius: 2px;
    background-color: #ff3d00;
    cursor: pointer;

    a {
      color: rgb(255, 255, 255);
      text-transform: uppercase;
    }

    &:hover {
      background-color: #dc3705;
    }

    .bkmes-button-bottom {
      cursor: pointer;
      margin: 10px 0;
      padding: 5px 25px;
      font-weight: 600;
      line-height: 20px;
      font-size: 1.28571rem;
      text-align: center;
      border: 1px solid #3f7fa4;

      a {
        color: #3f7fa4;
        text-transform: uppercase;
      }

      &:hover {
        background-color: #e8e8e9;

        a {
          color: #4996c2;
        }
      }
    }
  }
}

.bkbonus-button-top {
  margin-top: 25px;
  padding: 10px 25px;
  font-weight: 600;
  line-height: 20px;
  font-size: 1.28571rem;
  text-align: center;
  border-radius: 2px;
  background-color: #3f7fa4;
  cursor: pointer;

  a {
    color: rgb(255, 255, 255);
    text-transform: uppercase;
  }

  &:hover {
    background-color: #4996c2;
  }
}

.star-button-top {
  margin-top: 15px;
  padding: 5px 25px;
  font-weight: 600;
  line-height: 20px;
  font-size: 1.28571rem;
  text-align: center;
  border-radius: 2px;
  background-color: #3f7fa4;
  cursor: pointer;

  a {
    color: rgb(255, 255, 255);
    text-transform: uppercase;
  }

  &:hover {
    background-color: #4996c2;
  }
}